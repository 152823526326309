import React from 'react';
import '../css/pie.css';
import connect from "react-redux-connect-lifecycle";
import packageJson from '../../package.json';
import ImgWithTooltip from './ImgWithTooltip';

const Pie = (props) =>{

    return (
        <footer className="page-footer font-small teal pt-4 pie container-fluid">
            <div className="text-center text-md-left">
                <div className="row">
                    <div align="justify" style={{paddingRight: '15px', paddingLeft: '15px'}}>
                        <p>Verificaciones Industriales de Andalucía, S.A. (VEIASA) tratará la información que nos facilite para enviarle comunicaciones relativas a la ITV de su vehículo,
                            como el aviso de caducidad de su ITV, en base a su consentimiento. No se cederán datos a terceros, salvo obligación legal.
                            Tiene derecho a acceder, rectificar y suprimir los datos, solicitar la portabilidad, oponerse al tratamiento y solicitar su limitación.<br/>
                            Más información en nuestro <a id="link-legalFooter0" target="_blank" rel="noopener noreferrer" href="https://www.veiasa.es/aviso_legal" tabIndex="11">aviso legal y política de privacidad.</a></p>
                    </div>
                </div>
            </div>

            <div className="row d-flex align-items-center">

                <div className="col-md-7 col-lg-8">
                    <p className="text-center text-md-left">© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C/ Albert Einstein, 2. 41092 Sevilla | Teléfono de Atención al Cliente: 955 54 89 55
                    </p>
                </div>

                <div className="col-md-5 col-lg-4 ml-lg-0">
                    <div className="text-center text-md-right">
                        <ul className="list-unstyled list-inline">
                            <li className="list-inline-item">
                                <a id="link-legalFooter1" href="http://www.veiasa.es/aviso_legal" target="_blank" rel="noopener noreferrer"
                                   onClick={"window.open(this.href); return false;"} tabIndex="12">Aviso Legal y Política de Privacidad</a>
                            </li>
                            <li className="list-inline-item">
                                <a id="link-legalFooter2" target="_blank" rel="noopener noreferrer" href="https://www.veiasa.es/la_empresa/reconocimientos"
                                   onClick="window.open(this.href); return false;" tabIndex="13"><span>Acreditaciones ENAC</span></a>
                            </li>
                            <li className="list-inline-item">
                                <ImgWithTooltip srcImg={"https://www.w3.org/WAI/wcag1AA"} imageId="footer-image"
                                    imgWidth={44} imgHeight={16} imgUrlToGo={"https://www.w3.org/WAI/WCAG1AA-Conformance"}
                                    tooltipWidth={'310px'} textTooltip={"Explicación del Nivel Doble-A de Conformidad"}
                                    tooltipTop={true} backColor={"#337AB7"} textColor={'#fff'}
                                    altText={"Icono de conformidad con el Nivel Doble-A, de las Directrices de Accesibilidad para el Contenido Web 1.0 del W3C-WAI"}
                                    closableColor={'#fff'} alignRight={true} tabIndx="14"
                                />
                            </li>
                            <li><a style={{color:'#FFF'}}>{packageJson.version}</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default connect()(Pie);