import React from 'react';
import Parser from "html-react-parser";
import connect from 'react-redux-connect-lifecycle';
import Spinner from "../components/spinner";
import textos_idiomas from "../components/textos";
import constants from "../utils/constants";
import '../css/react-tabs.css';
import '../css/tabs.css';
import "react-table/react-table.css";
import webservice from "../api/webservice";
import {ClearState, SetError, SetState, SetTarget, ShowCalendario, ShowCorreo, ShowGrabaOK, ShowSpin, ShowTelefono, UpdateChkDatContacto} from '../utils/actions';
import cookie from "react-cookies";
import {Button, Modal, ModalBody, ModalFooter} from 'reactstrap';
import moment from "moment/moment";
import {Cell, Row} from 'react-responsive-table';
import {deviceDetect, isMobileOnly, isTablet, isMobileSafari, isMobile, isChrome, isIE, isFirefox} from "react-device-detect";


const datosContacto = (props) => {
    let lcl_CORREO_E = props.lcl_CORREO_E_camuflado;
    let lcl_TELEFONO = props.lcl_TELEFONO_camuflado;
    let props_aux = props.AUTORIZA;


    function setTexto(texto) {
        return textos_idiomas[texto][props.idioma]
    }

    function anterior(event) {
        event.preventDefault();
        //props.history.goBack();
        window.location.reload();
    }

    function validaTelefono(telefono) {
        let plantilla = /([6|7]{1})([0-9]{8}$)/;
        return telefono.match(plantilla)
    }

    function validaEmail(email) {
        let plantilla = /[a-z0-9\-_.-]+(?:\.[a-z0-9\-_.-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
        return email.match(plantilla)
    }

    function onSubmit(event) {
        event.preventDefault();
        if (!props.lcl_chk_ninguna && !props.lcl_chk_no_propietario && !props.lcl_chk_telefono && !props.lcl_chk_correo_e) {
            props.SetError(constants.SET_ERROR, 'Es necesario seleccionar al menos un medio de contacto');
            return
        }
        if (props.lcl_chk_ninguna && !props.lcl_chk_ninguna_confirma) {
            props.SetError(constants.SET_ERROR, 'Es necesario confirmar que no desea recibir información');
            return
        }
        if (props.show_telefono) {
            if (props.lcl_TELEFONO !== '' && !validaTelefono(props.lcl_TELEFONO)) {
                props.SetError(constants.SET_ERROR, 'El formato del número de teléfono no es correcto');
                return
            }

            if (props.lcl_TELEFONO === '') {
                props.SetError(constants.SET_ERROR, 'Es necesario introducir un número de teléfono');
                return
            }

        }
        if (props.show_correo_e) {
            if (props.lcl_CORREO_E === '') {
                props.SetError(constants.SET_ERROR, 'Es necesario introducir un correo electrónico');
                return
            }
            if (props.lcl_CORREO_E_repetido === '') {
                props.SetError(constants.SET_ERROR, 'Es necesario introducir un correo electrónico repetido');
                return
            }
            if (!validaEmail(props.lcl_CORREO_E)) {
                props.SetError(constants.SET_ERROR, 'El correo electrónico no es correcto');
                return
            }
            if (!validaEmail(props.lcl_CORREO_E_repetido)) {
                props.SetError(constants.SET_ERROR, 'El correo electrónico repetido no es correcto');
                return
            }
            if (props.lcl_CORREO_E !== props.lcl_CORREO_E_repetido) {
                props.SetError(constants.SET_ERROR, 'Los correos electrónicos no coinciden');
                return
            }
        }
        if (!props.lcl_chk_alguna && !props.lcl_chk_ninguna && !props.lcl_chk_no_propietario) {
            props.SetError(constants.SET_ERROR, 'Es necesario indicar si se desea que se pongan en contacto conmigo o no');
            return
        }
        let token_auth = cookie.load('token');
        if (props.lcl_chk_no_propietario) {
            let param = document.location.hash.replace("#/sms/?", "");
            webservice.grabaClienteAutorizaError(param).then(resp => {
                if (resp.error !== undefined && resp.error !== '') {
                    let error = resp.error;
                    props.ShowSpin(false);
                    props.SetError(constants.SET_ERROR, error);
                    throw error;
                }
                props.SetTarget('showDesvincula', true);
            }).catch(error => {
                props.ShowSpin(false);
                props.SetError(constants.SET_ERROR, error);
            });
        }
        if (props.AUTORIZA && !props.lcl_chk_no_propietario) {
            let D4 = '';
            if (props.AUTORIZA.detalle) {
                D4 = props.AUTORIZA.detalle.indexOf('4') > 0 ? '4' : '';
            }
            props.AUTORIZA.detalle = (props.lcl_chk_alguna ? '1' : '') + (props.D2 ? '2' : '') + (props.D3 ? '3' : '') + D4;
            props.AUTORIZA.correo_e = (props.lcl_CORREO_E ? props.lcl_CORREO_E : '');
            props.AUTORIZA.telefono = (props.lcl_TELEFONO ? props.lcl_TELEFONO : '');
        }
        if (props.opcion === '1' && !props.lcl_chk_no_propietario) {
            let accesoGlobal = {
                metodo: "ConfirmacionFinal",
                identificador: props.identificador,
                navegador: props.navegador,
                plataforma: props.plataforma
            };
            props.ShowSpin(true);
            webservice.grabaClienteAutoriza(token_auth, props, accesoGlobal).then(resp => {
                if (resp.error !== undefined && resp.error !== '') {
                    let error = resp.error;
                    props.ShowSpin(false);
                    props.SetError(constants.SET_ERROR, error);
                    throw error;
                }
                props.ShowGrabaOK(true);
            }).catch(error => {
                props.ShowSpin(false);
                props.SetError(constants.SET_ERROR, error);
            });
        } else if (props.opcion !== '1' && !props.lcl_chk_no_propietario) {
            if (props.opcion_p === '0')
                props.history.push({pathname: './tipoInspeccion'});
            if (props.opcion_p === '2')
                props.history.push({pathname: './reformas'});
        }
    }

    function handleEnter(event) {
        if (event.keyCode === 13) {
            event.preventDefault();
            onSubmit(event)
        }
    }

    function updateCheckboxNoTitular(e) {
        const target = e.target;
        if ((!props.lcl_chk_no_propietario && target.name === 'lcl_chk_no_propietario') || props.lcl_chk_no_propietario) {
            props.SetTarget('lcl_chk_telefono', false);
            props.SetTarget('lcl_chk_correo_e', false);
            props.SetTarget('lcl_chk_no_propietario', true);
            props.SetTarget('lcl_chk_ninguna', false);
            props.SetTarget('lcl_chk_alguna', false);
            props.SetTarget('lcl_TELEFONO', '');
            props.SetTarget('lcl_TELEFONO_camuflado', '');
            props.SetTarget('lcl_CORREO_E', '');
            props.SetTarget('lcl_CORREO_E_camuflado', '');
            props.SetTarget('D2', false);
            props.SetTarget('D3', false);
            props.ShowTelefono(false);
            props.ShowCorreo(false);
        }
    }

    function updateCheckbox(e, number) {
        let target = e;
        let chk = null;
        if (number === 1 || number === 2) {
            target = e;
            chk = target.checked;
        } else {
            target = e.target;
            chk = target.checked;
        }

        props.SetError(constants.SET_ERROR, '');
        if (props.lcl_chk_ninguna && target.name === 'lcl_chk_ninguna') {
            props.SetTarget('lcl_chk_ninguna_confirma', false);
        }
        props.SetTarget(target.name, chk);

        // Lógica donde controlamos las opciones activas o no de los checkbox teniendo en cuenta el modificar
        if (target.name === 'lcl_chk_telefono') {
            if (!chk) {
                props.SetTarget('lcl_TELEFONO', '');
                props.SetTarget('lcl_TELEFONO_camuflado', '');
                props.ShowTelefono(chk);
                props.SetTarget('modificandoTlfContacto', false);
            } else {
                if (props_aux.telefono !== '' && props_aux.telefono !== null) {
                    props.SetTarget('lcl_TELEFONO', props_aux.telefono);
                    props.SetTarget('lcl_TELEFONO_camuflado', props_aux.telefono);
                    if (props.modificandoTlfContacto === true) {
                        props.ShowTelefono(chk);
                        props.ShowTelefono(!props.show_telefono);
                    }
                } else {
                    props.SetTarget('modificandoTlfContacto', true);
                    props.ShowTelefono(chk);
                    props.ShowTelefono(!props.show_telefonoe);
                }
            }
        } else if (target.name === 'lcl_chk_correo_e') {
            if (!chk) {
                props.SetTarget('lcl_CORREO_E', '');
                props.SetTarget('lcl_CORREO_E_camuflado', '');
                props.ShowCorreo(chk);
                props.SetTarget('modificandoEmailContacto', false);
            } else {
                if (props_aux.correo_e !== '' && props_aux.correo_e !== null) {
                    props.SetTarget('lcl_CORREO_E', props_aux.correo_e);
                    props.SetTarget('lcl_CORREO_E_camuflado', props_aux.correo_e);
                    if (props.modificandoEmailContacto === true) {
                        props.ShowCorreo(chk);
                        props.ShowCorreo(!props.show_correo_e);
                    }
                } else {
                    props.SetTarget('modificandoEmailContacto', true);
                    props.ShowCorreo(chk);
                    props.ShowCorreo(!props.show_correo_e);
                }
            }
        }
        if ((!props.lcl_chk_ninguna && target.name === 'lcl_chk_ninguna') || props.lcl_chk_ninguna) {
            props.SetTarget('lcl_chk_telefono', false);
            props.SetTarget('lcl_chk_correo_e', false);
            props.SetTarget('lcl_chk_alguna', false);
            props.SetTarget('lcl_TELEFONO', '');
            props.SetTarget('lcl_TELEFONO_camuflado', '');
            props.SetTarget('lcl_CORREO_E', '');
            props.SetTarget('lcl_CORREO_E_camuflado', '');
            props.SetTarget('D2', false);
            props.SetTarget('D3', false);
            props.ShowTelefono(false);
            props.ShowCorreo(false);
        }

        if (target.name === 'lcl_chk_alguna') {
            props.SetTarget('lcl_chk_alguna', true);
            props.SetTarget('lcl_chk_ninguna', false);
        }
        props.SetTarget('lcl_chk_no_propietario', false);

    }

    function editContactInfo(event) {
        event.preventDefault();
        const target = event.target;
        if (target.name === 'lcl_chk_modifica_correo_e') {
            props.SetTarget('modificandoEmailContacto', true);
            if (props.show_correo_e) {
                props.SetTarget('lcl_CORREO_E', lcl_CORREO_E);
            } else {
                props.SetTarget('lcl_CORREO_E', '');
            }
            props.ShowCorreo(!props.show_correo_e);
        }
        if (target.name === 'lcl_chk_modifica_telefono') {
            props.SetTarget('modificandoTlfContacto', true);
            if (props.show_telefono) {
                props.SetTarget('lcl_TELEFONO', lcl_TELEFONO);
            } else {
                props.SetTarget('lcl_TELEFONO', '');
            }
            props.ShowTelefono(!props.show_telefono);
        }
    }

    function updateState(e) {
        const target = e.target;
        props.SetTarget(target.name, target.value.toLowerCase());
        props.ShowCalendario(false);
        props.SetError(constants.SET_ERROR, '');
    }

    function cierraInfoRGPD(event) {
        cierraInfo(event);

    }


    function cambiaCheckCorreo(event) {
        event.preventDefault();
        document.getElementById("lcl_chk_correo_e").checked = false;
        updateCheckbox(document.getElementById("lcl_chk_correo_e"), 1)
    }

    function cambiaCheckTelefono(event) {
        event.preventDefault();
        document.getElementById("lcl_chk_telefono").checked = false;
        updateCheckbox(document.getElementById("lcl_chk_telefono"), 2)
    }

    function cierraInfo(event) {
        props.ShowSpin(false);
        props.ShowGrabaOK(false);
        if (event.target.id === '00') {
            sessionStorage.setItem("datos_cliente", '');
            /*if (props.history)
                props.history.goBack();
            else
                document.location.hash = '#/';*/


            window.location.reload();
        } else {
            props.history.push({pathname: './citaInspeccion'});
        }
    }

    let error = '';
    if (props.error) {
        error = <div className="error" style={{alignItems: "center"}}>
            <img width={26} height={26} style={{marginRight: '3px'}}
                 src={require('../images/atencion.png')}
                 alt={"Logo Atención: signo de exclamación"}/>
            {props.error}
        </div>
    }

    return (
        <form className="txt3s">
            <div className="tituloNotificaciones">
                <p style={{padding: '5px'}}>Sus datos de contacto:</p>
            </div>
            <div hidden={props.opcion === '1'}>
                {Parser(setTexto('info_cliente'))}
            </div>
            <Spinner isOpen={props.showSpin}/>
            <Modal isOpen={props.showDesvincula} backdrop={true} className="customModal">
                <ModalBody className="customModalBody">
                    <table>
                        <tr>
                            <td><img width={30} src={require('../images/check.png')}/></td>
                            <td>{Parser("<b>Lamentamos las molestias. No volverá a recibir notificación de este vehículo.")}</td>
                        </tr>
                    </table>
                </ModalBody>
                <ModalFooter className="customModalFooter">
                    <table>
                        <tr>
                            <td>
                                <Button color="primary" onClick={() => {
                                    props.SetTarget('showDesvincula', false);
                                    document.location.hash = '#/';
                                }}>{setTexto('entendido')}</Button>
                            </td>
                        </tr>
                    </table>
                </ModalFooter>
            </Modal>
            <Modal isOpen={props.show_grabaOK} backdrop={true} className="customModal">
                <ModalBody className="customModalBody">
                    <table>
                        <tr>
                            <td>
                                <img width={30} src={require('../images/check.png')}/>{Parser("<b>" + setTexto('confirmar_rgpd_1'))}<br/><br/>
                                <div hidden={props.lcl_chk_ninguna_confirma}>{Parser(setTexto('confirmar_rgpd_2'))}<br/><br/></div>
                                <div hidden={props.lcl_chk_ninguna_confirma}>{Parser("&nbsp&nbsp&nbsp&nbsp&nbsp Matrícula: " + props.MATRICULA)}</div>
                                <div hidden={!props.BASTIDOR | props.lcl_chk_ninguna_confirma}>{Parser("&nbsp&nbsp&nbsp&nbsp&nbsp Bastidor: " + props.BASTIDOR)}</div>
                                <div hidden={!props.F_MAT | props.lcl_chk_ninguna_confirma}> {Parser("&nbsp&nbsp&nbsp&nbsp&nbsp Fecha matriculación: " + props.F_MAT)}</div>
                                <div hidden={!props.lcl_CORREO_E}>{Parser("&nbsp&nbsp&nbsp&nbsp&nbsp E-mail: " + props.lcl_CORREO_E)}</div>
                                <div hidden={!props.lcl_TELEFONO} align="justify">{Parser("&nbsp&nbsp&nbsp&nbsp&nbsp Nº Teléfono: " + props.lcl_TELEFONO)}</div>
                                <div hidden={!props.lcl_TELEFONO && !props.lcl_CORREO_E} align="justify">
                                    <br/>{Parser(setTexto('confirmar_rgpd_3'))}
                                    {(props.lcl_CORREO_E ? 'email a ' + props.lcl_CORREO_E : 'sms a ' + props.lcl_TELEFONO)}
                                    {Parser(setTexto('confirmar_rgpd_4'))}</div>
                            </td>
                        </tr>
                    </table>
                </ModalBody>
                <ModalFooter className="customModalFooter">
                    <table>
                        <tr>
                            <td><Button color="primary" id='00' onClick={cierraInfoRGPD}>{setTexto('entendido')}</Button></td>
                            <td><Button color="primary" id='01' onClick={cierraInfo} hidden={true}>{setTexto('solicitar_cita')}</Button></td>
                        </tr>
                    </table>
                </ModalFooter>
            </Modal>
            <div>
                <div>
                    <p hidden={!props.sinRegistro} style={{textAlign: 'justify'}}>{'Está dando de alta la matrícula '} <strong>{props.MATRICULA}</strong> {' para recibir los avisos de caducidad de la ITV'} </p>
                    <p hidden={props.sinRegistro} style={{textAlign: 'justify'}}>{'La matrícula '} <strong>{props.MATRICULA}</strong> {' ya está registrada en el servicio de notificaciones. Si desea modificar la información,' + ' introduzca los nuevos datos de contacto'}</p>
                </div>
                <table>
                    <Row>
                        <Cell style={{width: '350px'}}>
                            <input type='checkbox' id="lcl_chk_correo_e" name='lcl_chk_correo_e' onChange={updateCheckbox} checked={props.lcl_chk_correo_e}/>
                            <label>{setTexto('email')}</label>
                            <b style={{marginLeft: '20px'}}>{props.lcl_CORREO_E_camuflado}</b>
                        </Cell>
                        <Cell>
                            <button hidden={!props.lcl_CORREO_E_camuflado || props.modificandoEmailContacto} name='lcl_chk_modifica_correo_e' className="btn btn-primary boton" onClick={editContactInfo}>Modificar</button>
                            <button hidden={!props.lcl_CORREO_E_camuflado || props.modificandoEmailContacto} style={{marginLeft: '10px'}} name='eliminar_correo' className="btn btn-primary boton" onClick={cambiaCheckCorreo}>Eliminar</button>
                            <input type="text" hidden={!props.show_correo_e} name="lcl_CORREO_E" onKeyDown={handleEnter} placeholder="Introducir correo elect." value={props.lcl_CORREO_E} onChange={updateState} size={25} className="input inputDatCon" autoComplete={"email"}/>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <input type="text" hidden={!props.show_correo_e} name="lcl_CORREO_E_repetido" onKeyDown={handleEnter} placeholder="Reintroducir correo elect." value={props.lcl_CORREO_E_repetido} onChange={updateState} size={25} className="input inputDatCon" autoComplete={"email"} onPaste={(event) => event.preventDefault()}/>
                        </Cell>
                    </Row>
                </table>
                <table>
                    <Row>
                        <Cell style={{width: '350px'}}>
                            <input type='checkbox' id="lcl_chk_telefono" name='lcl_chk_telefono' onChange={updateCheckbox} checked={props.lcl_chk_telefono}/>
                            <label>{setTexto('n_telefono')}</label>
                            <b style={{marginLeft: '20px'}}>{props.lcl_TELEFONO_camuflado}</b>
                        </Cell>
                        <div style={{width: '270px'}}>
                            <button hidden={!props.lcl_TELEFONO_camuflado || props.modificandoTlfContacto} name='lcl_chk_modifica_telefono' className="btn btn-primary boton" onClick={editContactInfo}>Modificar</button>
                            <button hidden={!props.lcl_TELEFONO_camuflado || props.modificandoTlfContacto} name='eliminar_telefono' style={{marginLeft: '10px'}} className="btn btn-primary boton" onClick={cambiaCheckTelefono}>Eliminar</button>
                            <input type="text" hidden={(!props.show_telefono)} maxLength={9} name="lcl_TELEFONO" onKeyDown={handleEnter} placeholder="Introducir nº de telf." value={props.lcl_TELEFONO} onChange={updateState} size={20} className="input inputDatCon" autoComplete={"tel-national"}/>
                        </div>
                    </Row>
                </table>
                <table>
                    <tr><br></br></tr>
                    <tr><h6 className="seccionesNotificaciones">Notificaciones:</h6></tr>
                </table>
                <table>
                    <tr>
                        <td><input type='radio' name='lcl_chk_alguna' onChange={updateCheckbox} checked={props.lcl_chk_alguna}/></td>
                        <td style={{textAlign: 'justify'}}>{Parser(setTexto('si_deseo_contacto'))}</td>
                    </tr>
                    <tr>
                        <td><input type='radio' name='lcl_chk_ninguna' onChange={updateCheckbox} checked={props.lcl_chk_ninguna}/></td>
                        <td style={{textAlign: 'justify'}}>{Parser(setTexto('no_deseo_contacto'))}</td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <table hidden={!props.lcl_chk_ninguna}>
                                <tr>
                                    <td width="2%"></td>
                                    <td><input type='checkbox' name='lcl_chk_ninguna_confirma' onChange={updateCheckbox} checked={props.lcl_chk_ninguna_confirma}/></td>
                                    <td className='confirmacion'>{Parser(setTexto('confirma_no_contacto'))}</td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                    <tr hidden={!props.fromAcceso}>
                        <td><input type='radio' name='lcl_chk_no_propietario' onChange={updateCheckboxNoTitular} checked={props.lcl_chk_no_propietario}/></td>
                        <td style={{textAlign: 'justify'}}>{Parser('<b style="color:#00A8EA;">No soy titular del vehículo con matrícula ' + props.MATRICULA + '</b>')}</td>
                    </tr>
                </table>
                <table>
                    <tr><br></br></tr>
                    <tr>
                        <h6 className="seccionesNotificaciones">{setTexto('autoriza')}</h6>
                    </tr>
                    <tr>
                        <input name='D2' type='checkbox' onChange={updateCheckbox} checked={props.D2}/>{setTexto('autoriza_2')}
                    </tr>
                    <tr>
                        <input name='D3' type='checkbox' onChange={updateCheckbox} checked={props.D3}/>{setTexto('autoriza_3')}
                    </tr>
                </table>
                <br/>
                <table width="50%">
                    <Row>
                        <Cell>
                            <button className="btn btn-secondary botonAnterior" hidden={props.fromAcceso} onClick={anterior}>{setTexto('anterior')}</button>
                            <br/>
                        </Cell>
                        <Cell>
                            <button className="btn btn-primary boton" onClick={onSubmit}>{setTexto('confirmar')}</button>
                        </Cell>
                    </Row>
                    <tr><br></br></tr>
                </table>
            </div>
            <div>
                <p style={{paddingLeft: '40%'}}>{error}</p>
            </div>
        </form>
    );
};


const mapStateToProps = state => {
    return {
        metodo: state.updatedState.metodo,
        identificador: state.updatedState.identificador,
        plataforma: state.updatedState.plataforma,
        navegador: state.updatedState.navegador,
        fechaIdentificador: state.updatedState.fechaIdentificador,
        show_spin: state.updatedState.show_spin,
        error: state.updatedState.error,
        show_grabaOK: state.updatedState.show_grabaOK,
        show_calendario: state.updatedState.show_calendario,
        show_telefono: state.updatedState.show_telefono,
        show_correo_e: state.updatedState.show_correo_e,
        lcl_chk_telefono: state.updatedState.lcl_chk_telefono,
        lcl_chk_correo_e: state.updatedState.lcl_chk_correo_e,
        lcl_CORREO_E: state.updatedState.lcl_CORREO_E,
        lcl_chk_modifica_correo_e: state.updatedState.lcl_chk_modifica_correo_e,
        lcl_CORREO_E_repetido: state.updatedState.lcl_CORREO_E_repetido,
        lcl_TELEFONO: state.updatedState.lcl_TELEFONO,
        lcl_chk_modifica_telefono: state.updatedState.lcl_chk_modifica_telefono,
        lcl_chk_ninguna_confirma: state.updatedState.lcl_chk_ninguna_confirma,
        lcl_chk_botton_correo_eliminar: state.updatedState.lcl_chk_botton_correo_eliminar,
        lcl_chk_botton_telefono_eliminar: state.updatedState.lcl_chk_botton_telefono_eliminar,
        lcl_chk_ninguna: state.updatedState.lcl_chk_ninguna,
        lcl_chk_alguna: state.updatedState.lcl_chk_alguna,
        D2: state.updatedState.D2,
        D3: state.updatedState.D3,
        show_info_mat: state.updatedState.show_info_mat,
        show_info_bastidor: state.updatedState.show_info_bastidor,
        error_bastidor: state.updatedState.error_bastidor,
        error_matricula: state.updatedState.error_matricula,
        idioma: state.updatedState.idioma,
        info: state.updatedState.info,
        show_acceso: state.updatedState.show_acceso,
        LOCALIZADOR: state.updatedState.LOCALIZADOR,
        MATRICULA: state.updatedState.MATRICULA,
        MATRICULA_REMOLQUE: state.updatedState.MATRICULA_REMOLQUE,
        BASTIDOR: state.updatedState.BASTIDOR,
        l_remolque: state.updatedState.l_remolque,
        F_MAT: state.updatedState.F_MAT,
        f_mat: state.updatedState.f_mat,
        lcl_TELEFONO_camuflado: state.updatedState.lcl_TELEFONO_camuflado,
        lcl_CORREO_E_camuflado: state.updatedState.lcl_CORREO_E_camuflado,
        opcion_mb: state.updatedState.opcion_mb,
        opcion_p: state.updatedState.opcion_p,
        opcion: state.updatedState.opcion,
        show_eliminaOK: state.updatedState.show_eliminaOK,
        AUTORIZA: state.updatedState.AUTORIZA,
        UNIDADES: state.updatedState.UNIDADES,
        TIPOS_INSPECCIONES_REMOLQUE: state.updatedState.TIPOS_INSPECCIONES_REMOLQUE,
        TIPOS_INSPECCIONES: state.updatedState.TIPOS_INSPECCIONES,
        prevPag: state.updatedState.prevPag,
        lcl_chk_no_propietario: state.updatedState.lcl_chk_no_propietario,
        titulo: state.updatedState.titulo,
        sinRegistro: state.updatedState.sinRegistro,
        modificandoEmailContacto: state.updatedState.modificandoEmailContacto,
        modificandoTlfContacto: state.updatedState.modificandoTlfContacto,
        showDesvincula: state.updatedState.showDesvincula
    };
};

function existenDatos(props) {
    if (props.AUTORIZA) {
        return ((props.AUTORIZA.telefono !== null && props.AUTORIZA.telefono !== '') || (props.AUTORIZA.correo_e !== null && props.AUTORIZA.correo_e !== ''));
    }
}

function sinDatosContacto(props) {
    if (props.AUTORIZA) {
        if ((props.AUTORIZA.telefono === '' || props.AUTORIZA.telefono === null) && (props.AUTORIZA.correo_e === '' || props.AUTORIZA.correo_e === null)) {
            props.SetTarget('lcl_chk_telefono', false);
            props.SetTarget('lcl_chk_correo_e', false);
            props.SetTarget('lcl_chk_ninguna', false);
            props.SetTarget('lcl_chk_ninguna_confirma', false);
            props.SetTarget('lcl_chk_modifica_correo_e', false);
            props.SetTarget('lcl_chk_modifica_telefono', false);
            props.SetTarget('lcl_chk_alguna', true);
            props.SetTarget('show_telefono', false);
            props.SetTarget('lcl_chk_ninguna', false);
            props.SetTarget('lcl_TELEFONO', "");
            props.SetTarget('lcl_CORREO_E', "");
            props.SetTarget('lcl_TELEFONO_camuflado', false);
            props.SetTarget('lcl_CORREO_E_camuflado', "");
            props.SetTarget('lcl_CORREO_E_repetido', '');
            props.SetTarget('lcl_error', '');
        }
    } else {
        props.SetTarget('lcl_chk_ninguna', false);
        props.SetTarget('lcl_chk_ninguna_confirma', false);
    }
}

function actualizaEstado(props) {
    props.SetTarget('lcl_chk_telefono', (props.AUTORIZA.telefono !== null && props.AUTORIZA.telefono !== ""));
    props.SetTarget('lcl_chk_correo_e', (props.AUTORIZA.correo_e !== null && props.AUTORIZA.correo_e !== ""));
    props.SetTarget('lcl_chk_ninguna', false);
    props.SetTarget('lcl_chk_ninguna_confirma', false);
    props.SetTarget('lcl_chk_modifica_correo_e', false);
    props.SetTarget('lcl_chk_modifica_telefono', false);
    props.SetTarget('lcl_chk_alguna', true);
    props.SetTarget('lcl_chk_ninguna', false);
    if (props.AUTORIZA.detalle) {
        props.SetTarget('D2', props.AUTORIZA.detalle.includes("2"));
        props.SetTarget('D3', props.AUTORIZA.detalle.includes("3"));
    }
    props.SetTarget('lcl_TELEFONO', props.AUTORIZA.telefono);
    props.SetTarget('lcl_CORREO_E', props.AUTORIZA.correo_e);
    props.SetTarget('lcl_TELEFONO_camuflado', props.AUTORIZA.telefono);
    props.SetTarget('lcl_CORREO_E_camuflado', props.AUTORIZA.correo_e);
    props.SetTarget('lcl_CORREO_E_repetido', '');
    props.SetTarget('lcl_error', '');
    props.ShowCorreo(false);
    props.ShowTelefono(false);
}

function registroAcceso(accesoGlobal, props) {
    let fechaIdentificador = new Date();
    if (accesoGlobal.identificador === '' || accesoGlobal.identificador === undefined || accesoGlobal.identificador === null) {
        props.SetTarget('fechaIdentificador', fechaIdentificador.getTime());
    } else {
        let diferencia = fechaIdentificador.getTime() - props.fechaIdentificador;
        let diferenciaEnMins = diferencia / (1000 * 60 * 60);
        if (diferenciaEnMins > 0.25) {
            accesoGlobal.identificador = "";
            props.SetTarget('fechaIdentificador', fechaIdentificador.getTime());
        }
    }
    webservice.registraAcceso(accesoGlobal).then(resp => {
        accesoGlobal.identificador = resp.identificador;
        props.SetTarget('metodo', accesoGlobal.metodo)
        props.SetTarget('plataforma', accesoGlobal.plataforma)
        props.SetTarget('navegador', accesoGlobal.navegador)
        props.SetTarget('identificador', accesoGlobal.identificador)
    }).catch(error => {
        ShowSpin(false);
        SetError(constants.SET_ERROR, error);
    });

}

const mapDispatchToProps = dispatch => {
    return {
        ShowSpin(sSpin) {
            dispatch(ShowSpin(sSpin));
        },
        SetError(type, error) {
            dispatch(SetError(type, error));
        },
        ShowCalendario(sCalen) {
            dispatch(ShowCalendario(sCalen));
        },
        SetTarget(tName, tValue) {
            dispatch(SetTarget(tName, tValue));
        },
        ShowGrabaOK(sGraba) {
            dispatch(ShowGrabaOK(sGraba));
        },
        ShowTelefono(sTlf) {
            dispatch(ShowTelefono(sTlf));
        },
        ShowCorreo(sCorreo) {
            dispatch(ShowCorreo(sCorreo));
        },
        ClearState() {
            dispatch(ClearState());
        },
        SetState() {
            dispatch(SetState());
        },
        onComponentWillMount(props, event) {
        },
        onComponentDidMount(props) {
            if (props.fromAcceso) {
                let accesoGlobal = {
                    metodo: "InicioSesionSmS",
                    identificador: props.identificador,
                    navegador: deviceDetect().browserName,
                    plataforma: ""
                };
                if (isMobileSafari) {
                    accesoGlobal.navegador = "Safari Mobile"
                }
                if (isMobile) {
                    if (isChrome) {
                        accesoGlobal.navegador = "Chrome"
                    }
                    if (isIE) {
                        accesoGlobal.navegador = "IE"
                    }
                    if (isFirefox) {
                        accesoGlobal.navegador = "Firefox"
                    }
                }
                if (props.plataforma !== "") {
                    accesoGlobal.plataforma = props.plataforma;
                } else {
                    let plataforma = "";
                    if (isMobileOnly) {
                        plataforma = "Mobile";
                    } else if (isTablet) {
                        plataforma = "Tablet"
                    } else {
                        plataforma = "PC";
                    }
                    accesoGlobal.plataforma = plataforma;
                }
                registroAcceso(accesoGlobal, props);
                props.SetTarget('titulo', 'notificaciones');
                let param = document.location.hash.replace("#/sms/?", "");
                webservice.obtieneDatosPrerellenados(param).then(resp => {
                    if (resp.error !== undefined && resp.error !== '') {
                        let error = resp.error;
                        document.location.href = "/";
                        props.SetError(constants.SET_ERROR, error);
                        throw error;
                    }
                    let telefono = resp.resultado.telefono;
                    props.SetTarget('MATRICULA', resp.resultado.matricula)
                    props.SetTarget('opcion', '1');
                    props.SetTarget('sms', true);
                    let token_auth = cookie.load('token');
                    webservice.autorizarCita(token_auth, props, resp.resultado.matricula, props.BASTIDOR, resp.resultado.f_mat).then(resp => {
                        if (resp.error !== undefined && resp.error !== '') {
                            let error = resp.error;
                            props.SetError(constants.SET_ERROR, error);
                            throw error;
                        }
                        let token = resp.resultado.token;
                        if (resp.resultado.autoriza.telefono === null && resp.resultado.autoriza.detalle === null && resp.resultado.autoriza.correo_e === null) {
                            props.SetTarget('sinRegistro', true);
                        }
                        cookie.save('token', token, {path: '/'});
                        props.SetTarget('AUTORIZA', resp.resultado.autoriza);
                        if (resp.resultado.autoriza.telefono === '') {
                            props.SetTarget('lcl_chk_telefono', false);
                            props.ShowTelefono(false);
                        } else if (telefono !== null && telefono !== '') {
                            props.SetTarget('lcl_chk_telefono', true);
                        }
                        props.SetTarget('lcl_TELEFONO_camuflado', resp.resultado.autoriza.telefono !== null ? resp.resultado.autoriza.telefono : telefono);
                        props.SetTarget('lcl_TELEFONO', resp.resultado.autoriza.telefono !== null ? resp.resultado.autoriza.telefono : telefono);

                        if (resp.resultado.autoriza.telefono !== '' && resp.resultado.autoriza.telefono !== null) {
                            props.SetTarget('lcl_chk_telefono', true);
                        }
                        props.SetTarget('lcl_CORREO_E', resp.resultado.autoriza.correo_e);
                        if (resp.resultado.autoriza.correo_e !== '' && resp.resultado.autoriza.correo_e !== null) {
                            props.SetTarget('lcl_chk_correo_e', true);
                            props.SetTarget('lcl_CORREO_E_camuflado', resp.resultado.autoriza.correo_e);
                        }
                        if (resp.resultado.autoriza.detalle) {
                            props.SetTarget('lcl_chk_alguna', resp.resultado.autoriza.detalle.indexOf('1') !== -1);
                            props.SetTarget('D2', resp.resultado.autoriza.detalle.indexOf('2') !== -1);
                            props.SetTarget('D3', resp.resultado.autoriza.detalle.indexOf('3') !== -1);
                        }
                    }).catch(error => {
                        props.SetError(constants.SET_ERROR, error);
                    });

                }).catch(error => {
                    props.SetError(constants.SET_ERROR, error);
                });
                props.SetTarget('lcl_chk_telefono', false);
                props.SetTarget('lcl_chk_correo_e', false);
                props.SetTarget('lcl_chk_alguna', false);
                props.SetTarget('lcl_TELEFONO', '');
                props.SetTarget('lcl_TELEFONO_camuflado', '');
                props.SetTarget('lcl_CORREO_E', '');
                props.SetTarget('lcl_CORREO_E_camuflado', '');
                props.SetTarget('D2', false);
                props.SetTarget('D3', false);
                props.ShowTelefono(false);
                props.ShowCorreo(false);
                return;
            }
            if (props.AUTORIZA.telefono === null && props.AUTORIZA.telefono_c === null && props.AUTORIZA.correo_e === null && props.AUTORIZA.correo_e_c === null
                && props.AUTORIZA.detalle === null) {
                props.SetTarget('sinRegistro', true);
            } else {
                props.SetTarget('sinRegistro', false);
            }
            props.SetTarget('prevPag', 'datosContacto');
            let fecha = moment(props.F_MAT, ['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY', 'DDMMYY']);
            if (fecha.isValid()) {
                props.SetTarget('F_MAT', fecha.format('DD-MM-YYYY'));
            }
            if (existenDatos(props)) {
                actualizaEstado(props);
            } else {
                sinDatosContacto(props);
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(datosContacto);
