// Se debe pasar por parámetro el evento a usar
const handleEnter = (event, regDate, chassisNum) => {
    let footerImage = document.getElementById("footer-image");

    // Botón enter
    if (event.keyCode === 13) {
        event.preventDefault();

        if (document.activeElement.id === "footer-image") footerImage.click();
    }
};

export default handleEnter;
