import moment from 'moment';
import 'moment/locale/es.js';
import 'moment/locale/en-gb';
import 'moment/locale/de.js';
import 'moment/locale/fr.js';
import 'moment/locale/nl.js';

export function config_moment (idioma) {
    if (parseInt(idioma) === 0) {
        moment.locale('es');
    }
    if (parseInt(idioma) === 1) {
        moment.locale('en');
    }
    if (parseInt(idioma) === 2) {
        moment.locale('de');
    }
    if (parseInt(idioma) === 3) {
        moment.locale('fr');
    }
    if (parseInt(idioma) === 4) {
        moment.locale('nl');
    }

}

