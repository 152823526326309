const constants = {
    SET_IDIOMA: 'SET_IDIOMA',
    SET_ERROR: 'SET_ERROR',
    SET_ERROR_MATRICULA: 'SET_ERROR_MATRICULA',
    SET_ERROR_BASTIDOR: 'SET_ERROR_BASTIDOR',
    SET_INFO: 'SET_INFO',
    SET_INFOTIMER: 'SET_INFOTIMER',
    SET_DESCRIPCION: 'SET_DESCRIPCION',
    SET_F_MAT: 'SET_F_MAT',
    SET_TARGET: 'SET_TARGET',
    SET_SECONDS: 'SET_SECONDS',
    SET_TIME: 'SET_TIME',
    SET_CITA: 'SET_CITA',
    SET_TOKEN: 'SET_TOKEN',
    SET_AUTORIZA: 'SET_AUTORIZA',
    SET_UNIDADES_TOKEN: 'SET_UNIDADES_TOKEN',
    SET_TIPO_VEHICULO: 'SET_TIPO_VEHICULO',
    CLEAR_STATE: 'CLEAR_STATE',
    CLEAR_PARTIAL_STATE: 'CLEAR_PARTIAL_STATE',
    SET_ORDEN: 'SET_ORDEN',
    SET_TOKEN_INFO: 'SET_TOKEN_INFO',
    SET_TIPO_INSP_REMOLQUE: 'SET_TIPO_INSP_REMOLQUE',
    SET_TIPO_INSP: 'SET_TIPO_INSP',
    SET_TIPOS_INSPECCIONES: 'SET_TIPOS_INSPECCIONES',
    SET_TIPOS_INSPECCIONES_REMOLQUES: 'SET_TIPOS_INSPECCIONES_REMOLQUES',
    SET_TXT_UNIDAD: 'SET_TXT_UNIDAD',
    SET_TXT_TIPO_INSP: 'SET_TXT_TIPO_INSP',
    SET_TXT_TIPO_INSP_REMOLQUE: 'SET_TXT_TIPO_INSP_REMOLQUE',
    SET_UNIDAD: 'SET_UNIDAD',
    SET_PROVINCIA: 'SET_PROVINCIA',
    SET_UNIDADES_COMBO: 'SET_UNIDADES_COMBO',
    SET_TIPO_INS_NP: 'SET_TIPO_INS_NP',
    SET_TIPO_INS_NP_REMOLQUE: 'SET_TIPO_INS_NP_REMOLQUE',
    SET_L_ACEPTAR: 'SET_L_ACEPTAR',
    SET_L_REMOLQUE: 'SET_L_REMOLQUE',
    SET_L_TODAS_PROVINCIAS: 'SET_L_TODAS_PROVINCIAS',
    SET_CONEXFLOW: 'SET_CONEXFLOW',
    SET_HORAS_DISPONIBLES: 'SET_HORAS_DISPONIBLES',
    SET_DIAS_DISPONIBLES: 'SET_DIAS_DISPONIBLES',
    SET_HUECO: 'SET_HUECO',
    SET_POBLACIONES: 'SET_POBLACIONES',
    SHOW_INFO_MATBAST: 'SHOW_INFO_MATBAST',
    SHOW_CALENDARIO: 'SHOW_CALENDARIO',
    SHOW_SPIN: 'SHOW_SPIN',
    SHOW_ACCESO: 'SHOW_ACCESO',
    SHOW_HORAS: 'SHOW_HORAS',
    SHOW_GRABA_OK: 'SHOW_GRABA_OK',
    SHOW_ELIMINA_OK: 'SHOW_ELIMINA_OK',
    SHOW_PAGO_TARJETA: 'SHOW_PAGO_TARJETA',
    SHOW_TELEFONO: 'SHOW_TELEFONO',
    SHOW_CORREO_E: 'SHOW_CORREO_E',
    ELEMENT_SELECTED: 'ELEMENT_SELECTED',
    MODAL: 'MODAL',
    SEL_TAB: 'SEL_TAB',
    SEL_TAB_P: 'SEL_TAB_P',
    SEL_TAB_MB: 'SEL_TAB_MB',
    UPDATE_CHK_DAT_CONTACTO: 'UPDATE_CHK_DAT_CONTACTO',
    PAGINA_SIGUIENTE: 'PAGINA_SIGUIENTE',
    SET_LOCALIZADOR: 'SET_LOCALIZADOR',
    SET_STATE: 'SET_STATE',

    SET_FECHA: 'SET_FECHA',
    SET_MODO_ACCESO: 'SET_MODO_ACCESO',
    SET_VISIBILIDAD: 'SET_VISIBILIDAD',

    SET_DATOS_MIS_VEHICULOS: 'SET_DATOS_MIS_VEHICULOS',

    SET_MATRICULA: 'SET_MATRICULA',
    SET_FLAG_SOLICITA: 'SET_FLAG_SOLICITA',
    SET_FLAG_ELIMINA: 'SET_FLAG_ELIMINA'
};

export default constants;