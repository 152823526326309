import React from 'react';
import connect from "react-redux-connect-lifecycle";
import DatePicker from 'react-datepicker';
import '../css/calendario.css';
import moment from "moment/moment";
import 'react-datepicker/dist/react-datepicker.css';

const CalendarioComponent = (props) => {

    let highlightDates = [];
    let activos = [];

    if (props.Dias) {
        for (let title in props.Dias) {
            let Dias_moment = [];
            props.Dias[title].map((key, idx) => {
                Dias_moment.push(moment(key, 'DD-MM-YYYY'));
                activos.push(moment(key, 'DD-MM-YYYY'));
            });
            if (title === 'ocupados')
                var ocupados = Dias_moment;
            let Dias_title = {};
            Dias_title[title] = Dias_moment;
            highlightDates.push(Dias_title);
        }
        var vHighlightDates = highlightDates;
    }

    return (
        <div>
            <DatePicker
                selected={props.selected && props.selected.isValid() ? props.selected : null}
                onChange={props.onChange}
                dateFormat="DD-MM-YYYY"
                minDate={props.minDate}
                excludeDates={ocupados}
                includeDates={activos.length === 0 ? null : activos}
                id={'Id_calendario'}
                readOnly={false}
                showMonthDropdown={props.sel}
                showYearDropdown={props.sel}
                dropdownMode="select"
                inline={props.inline}
                highlightDates={vHighlightDates}
                locale="es-ES"
            />
        </div>
    );
};

export default connect()(CalendarioComponent);