import React, {useState} from 'react';
import ReactModal from "react-modal";
import cookie from 'react-cookies';
import Parser from "html-react-parser";
import connect from 'react-redux-connect-lifecycle';
import $ from "min-jquery";
import {config_moment} from "../components/config_moment";
import Spinner from "../components/spinner";
import Calendario from "../components/Calendario";
import textos_idiomas from "../components/textos";
import moment from "moment/moment";
import constants from "../utils/constants";
import '../css/react-tabs.css';
import '../css/tabs.css';
import "react-table/react-table.css";
import webservice from "../api/webservice";
import {Cell, Row, Table} from 'react-responsive-table';
import ImgWithTooltip from '../components/ImgWithTooltip';
import {isMobileOnly, isTablet, isMobileSafari, deviceDetect, isChrome, isMobile, isIE, isFirefox} from "react-device-detect";
import {ChangeOpc, ChangeOpcMb, ChangeOpcP, ClearState, SetError, SetFMat, SetIdioma, SetInfo, SetState, SetTarget, ShowAcceso, ShowCalendario, ShowEliminaOK, ShowInfoMatBast, ShowSpin} from '../utils/actions';

sessionStorage.setItem('numAct', 0);
let dato = true;
const estilo = {content: {top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '10%', transform: 'translate(-50%, -50%)',}};
const CitaSolicitud = (props) => {
    window.localStorage.setItem('refreshedPage', '');
    window.history.pushState({}, '');


    function cierraInfo() {
        props.ShowInfoMatBast(false, false);
        props.ShowEliminaOK(false);
    }

    function setTexto(texto) {
        return textos_idiomas[texto][props.idioma];
    }


    function selTab(event, tab) {
        event.preventDefault();
        props.SetError(constants.SET_ERROR, '');
        let opc = null;
        let parent = $(event.target).parent();
        let found = false;
        while (!found) {
            if (parent[0].id && (parent[0].id).indexOf("tab") >= 0) {
                found = true;
            } else {
                parent = $(parent).parent();
            }
        }
        let str = parent[0].id.substring(4, 5);
        if (str === '1') {
            props.SetTarget('F_MAT', '');
        } else if (str === '0') {
            props.SetTarget('BASTIDOR', '');
        }
        if (parent[0].id === 'tab00' && !event.target.id) {
            str = 1;
        }
        if (!event.target.id && !str) {
            return;
        }
        if (!event.target.id && str) {
            opc = str;
        } else {
            opc = event.target.id.substring(4, 5);
        }
        if (tab === '0') {
            props.ChangeOpc(opc);
        } else if (tab === '1') {
            props.ChangeOpcP(opc);
            if (opc === '0') {
                props.SetTarget('titulo', 'cita previa');
            } else if (opc === '1') {
                props.SetTarget('titulo', 'notificaciones');
            } else if (opc === '2') {
                props.SetTarget('titulo', 'cita previa');
            } else if (opc === '3') {
                props.SetTarget('titulo', 'facturación');
            }
        } else {
            props.ChangeOpcMb(opc);
        }
    }

    // Se debe pasar por parámetro el evento a usar
    const handleEnter = (event, regDate, chassisNum) => {
        let infoClientLink = document.getElementById("legal-link");
        let plateNumberInput = document.getElementById("MATRICULA");
        let registrationDateNumberTab = document.getElementById("tab50");
        let registrationDateInput = document.getElementById("F_MAT");
        let chassisNumberTab = document.getElementById("tab51");
        let chassisNumberInput = document.getElementById("BASTIDOR");
        let nextBtn = document.getElementById("btnSiguiente5");
        let linkLegalFooter0 = document.getElementById("link-legalFooter0");
        let linkLegalFooter1 = document.getElementById("link-legalFooter1");
        let linkLegalFooter2 = document.getElementById("link-legalFooter2");
        let footerImage = document.getElementById("footer-image");
        let captchaInput = document.getElementById("TXT_CAPTCHA")
        // Botón enter
        if (event.keyCode === 13) {
            event.preventDefault();
            if (document.activeElement.id === "legal-link") {
                infoClientLink.click();
            } else if (document.activeElement.id === "nextBtn") {
                nextBtn.click();
            } else if (document.activeElement.id === "link-legalFooter0") {
                linkLegalFooter0.click();
            } else if (document.activeElement.id === "link-legalFooter1") {
                linkLegalFooter1.click();
            } else if (document.activeElement.id === "link-legalFooter2") {
                linkLegalFooter2.click();
            } else if (document.activeElement.id === "footer-image") {
                footerImage.click();
            }
            // Botón tab
        } else if (event.keyCode === 9) {
            event.preventDefault();
            if (document.activeElement.id === "legal-link") {
                plateNumberInput.focus();
            } else if (document.activeElement.id === "plateNumberTab") {
                plateNumberInput.focus();
            } else if (document.activeElement.id === "MATRICULA") {
                registrationDateInput.focus();
                // Si la fecha de matriculación y el número de bastidor están vacíos,
                // se activa el campo fecha. También si el campo de fecha de matriculación está relleno
                if ((regDate.length === 0 && chassisNum.length === 0) || regDate.length > 0) {
                    // Si el bloque de fecha de matriculación NO está marcado se hace click
                    if (!registrationDateNumberTab.className.includes("active")) {
                        chassisNumberTab.className = registrationDateNumberTab.className.replace("active", '');
                        registrationDateNumberTab.className += " active";
                        registrationDateNumberTab.click();
                    }
                    registrationDateInput.focus();
                } else if (chassisNum.length !== 0) {
                    if (!chassisNumberTab.className.includes("active")) {
                        registrationDateNumberTab.className = chassisNumberTab.className.replace("active", '');
                        chassisNumberTab.className += " active";
                        chassisNumberTab.click();
                    }
                    chassisNumberInput.focus();
                }
            } else if (document.activeElement.id === "F_MAT") {
                if (regDate.length === 0) {
                    chassisNumberTab.click();
                    chassisNumberTab.focus();
                } else {
                    captchaInput.focus();
                }
            } else if (document.activeElement.id === "BASTIDOR") {
                captchaInput.focus();
            } else if (document.activeElement.id === "TXT_CAPTCHA") {
                nextBtn.focus();
            }
        }
    };


    function updateState(e) {
        const target = e.target;
        if (target.name === 'F_MAT') {
            props.SetFMat(target.value.toUpperCase());
        } else {
            props.SetTarget(target.name, target.value.toUpperCase().trim());
        }
        props.ShowCalendario(false);
    }

    function updateCheckbox(e) {
        const target = e.target;
        props.SetTarget(target.name, target.checked);
    }

    function ValidaFechaMat(event) {
        let valor = event.target.value;
        let txt_error = '';
        let fecha = moment(valor, ['DD-MM-YYYY', 'DD/MM/YYYY', 'DDMMYYYY', 'DDMMYY']);
        if (fecha.isValid()) {
            props.SetError(constants.SET_ERROR_MATRICULA, '');
            props.SetFMat(fecha.format('DD-MM-YYYY'));
            return fecha.format('DD-MM-YYYY');
        } else if (valor) {
            txt_error = 'El formato de la fecha de matriculación no es correcto';
        }
        props.SetError(constants.SET_ERROR_MATRICULA, txt_error);
    }

    function onChangeCalendario(date) {
        props.SetFMat(date.format('DD-MM-YYYY'), date);
        props.ShowCalendario(false);
    }

    function BloqueMatricula(tab) {
        return <div style={{padding: '0.25rem'}}>
            <table style={{margin: 'auto', height: '36px'}}><Row></Row></table>
            <Table>
                <div className="tab">
                    <button id="plateNumberTab" className="tablinks" style={{width: "100%"}}
                            onClickCapture={(e) => e.preventDefault()} tabIndex="1">
                        <Table className="txt3s">
                            <Row>
                                <Cell>{setTexto('matricula')}</Cell>
                            </Row>
                        </Table>
                    </button>
                </div>
            </Table>
            <div className="tabcontent fondoBlanco" style={{display: 'block'}}>
                <Table className="txt3s">
                    <Row>
                        <Cell>
                            <input type="text" id={"MATRICULA"} name="MATRICULA" className="input" onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} value={props.MATRICULA || ''} maxLength={9} onChange={updateState} size={20} placeholder="Introducir matrícula" tabIndex="2"/>
                        </Cell>
                        <Cell className="container">
                            <div id="tooltip">
                                <ImgWithTooltip srcImg={require('../images/info.png')} imageId="imgInfoId" imgWidth={20} imgHeight={20} tooltipTop={false} tooltipWidth={'400px'} textTooltip={setTexto('info_mat')} backColor={"#337AB7"} textColor={'#fff'} closableColor={'#fff'} altText={"Introduzca la matrícula de su vehículo"}/>
                            </div>
                        </Cell>
                    </Row>
                    <Row></Row>
                </Table>
                <Table className="txt3s">
                    <Row>
                        <Cell>
                            <div hidden={!show_remolque}>
                                <input name='l_remolque' type='checkbox' onChange={updateCheckbox} checked={props.l_remolque}/>
                                <p style={{fontSize: '12px', marginBottom: '0px'}}>{setTexto('insp_remolque')}</p>
                            </div>
                        </Cell>
                    </Row>
                </Table>
                <Table className="txt3s">
                    <Row hidden={!(props.l_remolque && show_remolque)}>
                        <Cell>
                            <div hidden={!(props.l_remolque && show_remolque)}><p style={{fontSize: '12px', margin: 'auto'}}>{setTexto('remolque')}</p></div>
                        </Cell>
                        <Cell>
                            <input hidden={!(props.l_remolque && show_remolque)} maxLength={9} type="text" name="MATRICULA_REMOLQUE" value={props.MATRICULA_REMOLQUE} onClickCapture={updateState} onChange={updateState} size={10} className="input" onKeyDown={handleEnter}/>
                        </Cell>
                    </Row>
                </Table>
            </div>
        </div>
    }

    function cambiaDato(valor) {
        dato = valor;
    }

    function BloqueFechamBastidor(tab) {
        return <div hidden={!(props.opcion !== '2')} style={{margin: 'auto', padding: '0.25rem'}}>
            <Table>
                <table style={{margin: 'auto'}}>
                    <Row>
                        <p style={{textAlign: 'center', marginBottom: '0px'}}>Introduzca uno de los dos:</p>
                    </Row>
                    <Row> </Row>
                </table>
                <div className="tab" onClickCapture={(e) => selTab(e, tab)}>
                    <button id={"tab" + tab + "0"} className="tablinks active" onClick={() => cambiaDato(true)} tabIndex="3">
                        <Table className="txt3s">
                            <Row>
                                <Cell>{setTexto('fecha_mat')}</Cell>
                            </Row>
                        </Table>
                    </button>
                    <button id={"tab" + tab + "1"} className="tablinks" onClick={() => cambiaDato(false)} tabIndex="5">
                        <Table className="txt3s">
                            <Row>
                                <Cell>{setTexto('bastidor')}</Cell>
                            </Row>
                        </Table>
                    </button>
                </div>
            </Table>
            <div id={"tabc" + tab + "0"} className="tabcontent fondoBlanco" style={{display: 'block'}}>
                <Table>
                    <tbody>
                    <tr>
                        <td>
                            <input type="text" id={"F_MAT"} name="F_MAT" className="input" maxLength={10} tabIndex="4" placeholder={setTexto('fecha_formato')} onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} value={props.F_MAT || ''} onChange={updateState} onBlur={ValidaFechaMat} size={20}/>
                        </td>
                        <td>
                            <div style={{visibility: props.show_calendario ? '' : 'hidden', marginLeft: "60px", position: "absolute"}}>
                                <Calendario maxDate={moment()} sel={true} inline={props.show_calendario} selected={props.f_mat} onChange={onChangeCalendario}/>
                            </div>
                        </td>
                        <td className="container">
                            <img className="item" width={20} height={20} onClickCapture={() => props.ShowCalendario(!props.show_calendario)} src={require('../images/calendario.jpg')} alt='Seleccione o inserte la fecha de matriculación del vehículo'/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
            <div id={"tabc" + tab + "1"} className="tabcontent fondoBlanco">
                <Table>
                    <tbody style={{display: 'block'}}>
                    <tr style={{float: 'right'}}>
                        <td>
                            <input type="text" id={"BASTIDOR"} name="BASTIDOR" className="input" maxLength={6} onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} tabIndex="6" placeholder="Introducir nº bastidor" value={props.BASTIDOR || ''} onChange={updateState} size={20}/>
                        </td>
                    </tr>
                    </tbody>
                </Table>
            </div>
        </div>
    }


    function update(props, dict) {
        return ({
            ...props,
            token: dict.token,
            AUTORIZA: dict.AUTORIZA,
            UNIDADES: dict.UNIDADES,
            TIPOS_INSPECCIONES: dict.TIPOS_INSPECCIONES,
            TIPOS_INSPECCIONES_REMOLQUE: dict.TIPOS_INSPECCIONES_REMOLQUE
        })
    }


    function onSubmit(event, fecha = null) {
        props.SetInfo('');
        event.preventDefault();
        let error = false;
        props.SetError(constants.SET_ERROR, "");
        props.SetError(constants.SET_ERROR_MATRICULA, "");
        props.SetError(constants.SET_ERROR_BASTIDOR, "");
        if (dato === true) {
            if (props.MATRICULA === '' && props.F_MAT === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido ni matrícula, ni fecha de matriculación.");
                error = true
            } else if (props.MATRICULA === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido la matrícula");
                error = true
            } else if (props.F_MAT === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido la fecha de matriculación.");
                error = true
            }
        } else {
            if (props.MATRICULA === '' && props.BASTIDOR === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido ni matrícula, ni el numero de bastidor.");
                error = true
            } else if (props.MATRICULA === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido la matrícula");
                error = true
            } else if (props.BASTIDOR === '') {
                props.SetError(constants.SET_ERROR_MATRICULA, "No se ha introducido el número de bastidor.");
                error = true
            }
        }
        if (error) return;
        props.ShowSpin(true);
        let F_MAT = (fecha === null) ? props.F_MAT : fecha;
        props.SetTarget('F_MAT', F_MAT);
        let accesoGlobal = {
            metodo: "MetodoSiguiente",
            identificador: props.identificador,
            navegador: props.navegador,
            plataforma: props.plataforma,
            txtCaptcha: props.TXT_CAPTCHA,
        };
        let token_auth = cookie.load('token');
        webservice.autorizarCita(token_auth, props, props.MATRICULA, props.BASTIDOR, F_MAT, accesoGlobal).then(resp => {
            if (resp.error !== undefined && resp.error !== '') {
                let error = resp.error;
                props.ShowSpin(false);
                props.SetError(constants.SET_ERROR, error);
                throw error;
            }
            props.ShowSpin(false);
            let dict = {};
            Object.keys(resp.resultado).map((key) => {
                dict[key] = resp.resultado[key];
            });
            let newProps = update(props, dict);
            let token = newProps['token'];
            cookie.save('token', token, {path: '/'});
            props.SetState(newProps);
            props.SetError(constants.SET_ERROR, '');
            props.SetError(constants.SET_ERROR_MATRICULA, '');
            props.SetError(constants.SET_ERROR_BASTIDOR, '');
            props.SetTarget('AUTORIZA', resp.resultado.autoriza);
            props.history.push({pathname: './datosContacto', state: true});
        }).catch(error => {
            props.SetError(constants.SET_ERROR, error);
            props.ShowSpin(false);
        });
    }

    let error_matricula = "";
    let error = "";
    let info = "";
    config_moment(props.idioma);
    let show_remolque = props.opcion === '0' && props.opcion_p === '0';
    if (props.error_matricula) {
        error_matricula = <div className="mensaje">
            <div className="error"><img width={26} height={26} style={{marginRight: '3px'}} alt={"Logo Atención: signo de exclamación"} src={require('../images/atencion.png')}/>{props.error_matricula} </div>
        </div>
    }
    if (props.error) {
        error = <div className="error">
            <img width={26} height={26} style={{marginRight: '3px'}}
                 alt={"Logo Atención: signo de exclamación"}
                 src={require('../images/atencion.png')}/>{props.error}</div>
    }
    if (props.info) {
        info = <div className="alert alert-warning">{props.info}</div>
    }
    setTabs(props);
    return (
        <Table>
            <form className="form-container">
                <div></div>
                <Spinner isOpen={props.show_spin}/>
                <div></div>
                <ReactModal isOpen={props.show_info_mat} contentLabel="" style={estilo}>
                    <div className="App">
                        <h5>{setTexto('info_mat')}</h5>
                    </div>
                    <div align="center">
                        <button className="btn" onClick={cierraInfo}>{setTexto('entendido')}</button>
                    </div>
                </ReactModal>
                <table className="txt3 nombreUsuarioContainer" hidden={true}></table>
                <Table>
                    <div className="tab" onClickCapture={(e) => selTab(e, '0')}>
                        <button id="tab00" className="tablinks" hidden={true}>
                            <Table className="txt3s">
                                <Row>
                                    <Cell>{setTexto('solicitar')}</Cell>
                                </Row>
                            </Table>
                        </button>
                        <button id="tab01" className="tablinks" style={{backgroundColor: '#EBEBE8', color: '#EBEBE8', cursor: 'default'}}>
                            <Table className="txt3s">
                                <Row>
                                    <Cell>{setTexto('contacto')}</Cell>
                                </Row>
                            </Table>
                        </button>
                    </div>
                </Table>
                <Table>
                    <div id="tabc00" className="tabcontent">
                        <table style={{margin: 'auto'}}>
                            <tbody>
                            <tr>
                                <td>
                                </td>
                            </tr>
                            <tr>
                                <Table>
                                    <Row>
                                        <div style={{padding: '0.25rem'}}>
                                            <table style={{margin: 'auto', height: '36px'}}><Row></Row></table>
                                            <Table>
                                                <div className="tab">
                                                    <button id="plateNumberTab" className="tablinks" style={{width: "100%"}}
                                                            onClickCapture={(e) => e.preventDefault()} tabIndex="1">
                                                        <Table className="txt3s">
                                                            <Row>
                                                                <Cell>{setTexto('matricula')}</Cell>
                                                            </Row>
                                                        </Table>
                                                    </button>
                                                </div>
                                            </Table>
                                            <div className="tabcontent fondoBlanco" style={{display: 'block'}}>
                                                <Table className="txt3s">
                                                    <Row>
                                                        <Cell>
                                                            <input type="text" id={"MATRICULA" + 2} name="MATRICULA" className="input" onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} value={props.MATRICULA || ''} maxLength={9} onChange={updateState} size={20} placeholder="Introducir matrícula" tabIndex="2"/>
                                                        </Cell>
                                                        <Cell className="container">
                                                            <div id="tooltip">
                                                                <ImgWithTooltip srcImg={require('../images/info.png')} imageId="imgInfoId" imgWidth={20} imgHeight={20} tooltipTop={false} tooltipWidth={'400px'} textTooltip={setTexto('info_mat')} backColor={"#337AB7"} textColor={'#fff'} closableColor={'#fff'} altText={"Introduzca la matrícula de su vehículo"}/>
                                                            </div>
                                                        </Cell>
                                                    </Row>
                                                    <Row></Row>
                                                </Table>
                                                <Table className="txt3s">
                                                    <Row>
                                                        <Cell>
                                                            <div hidden={!show_remolque}>
                                                                <input name='l_remolque' type='checkbox' onChange={updateCheckbox} checked={props.l_remolque}/>
                                                                <p style={{fontSize: '12px', marginBottom: '0px'}}>{setTexto('insp_remolque')}</p>
                                                            </div>
                                                        </Cell>
                                                    </Row>
                                                </Table>
                                                <Table className="txt3s">
                                                    <Row hidden={!(props.l_remolque && show_remolque)}>
                                                        <Cell>
                                                            <div hidden={!(props.l_remolque && show_remolque)}><p style={{fontSize: '12px', margin: 'auto'}}>{setTexto('remolque')}</p></div>
                                                        </Cell>
                                                        <Cell>
                                                            <input hidden={!(props.l_remolque && show_remolque)} maxLength={9} type="text" name="MATRICULA_REMOLQUE" value={props.MATRICULA_REMOLQUE} onClickCapture={updateState} onChange={updateState} size={10} className="input" onKeyDown={handleEnter}/>
                                                        </Cell>
                                                    </Row>
                                                </Table>
                                            </div>
                                        </div>
                                        <div hidden={!(props.opcion !== '2')} style={{margin: 'auto', padding: '0.25rem'}}>
                                            <Table>
                                                <table style={{margin: 'auto'}}>
                                                    <Row>
                                                        <p style={{textAlign: 'center', marginBottom: '0px'}}>Introduzca uno de los dos:</p>
                                                    </Row>
                                                    <Row> </Row>
                                                </table>
                                                <div className="tab" onClickCapture={(e) => selTab(e, 2)}>
                                                    <button id={"tab" + 2 + "0"} className="tablinks active" onClick={() => cambiaDato(true)} tabIndex="3">
                                                        <Table className="txt3s">
                                                            <Row>
                                                                <Cell>{setTexto('fecha_mat')}</Cell>
                                                            </Row>
                                                        </Table>
                                                    </button>
                                                    <button id={"tab" + 2 + "1"} className="tablinks" onClick={() => cambiaDato(false)} tabIndex="5">
                                                        <Table className="txt3s">
                                                            <Row>
                                                                <Cell>{setTexto('bastidor')}</Cell>
                                                            </Row>
                                                        </Table>
                                                    </button>
                                                </div>
                                            </Table>
                                            <div id={"tabc" + 2 + "0"} className="tabcontent fondoBlanco" style={{display: 'block'}}>
                                                <Table>
                                                    <tbody>
                                                    <tr>
                                                        <td>
                                                            <input type="text" id={"F_MAT" + 2} name="F_MAT" className="input" maxLength={10} tabIndex="4" placeholder={setTexto('fecha_formato')} onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} value={props.F_MAT || ''} onChange={updateState} onBlur={ValidaFechaMat} size={20}/>
                                                        </td>
                                                        <td>
                                                            <div style={{visibility: props.show_calendario ? '' : 'hidden', marginLeft: "60px", position: "absolute"}}>
                                                                <Calendario maxDate={moment()} sel={true} inline={props.show_calendario} selected={props.f_mat} onChange={onChangeCalendario}/>
                                                            </div>
                                                        </td>
                                                        <td className="container">
                                                            <img className="item" width={20} height={20} onClickCapture={() => props.ShowCalendario(!props.show_calendario)} src={require('../images/calendario.jpg')} alt='Seleccione o inserte la fecha de matriculación del vehículo'/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                            <div id={"tabc" + 2 + "1"} className="tabcontent fondoBlanco">
                                                <Table>
                                                    <tbody style={{display: 'block'}}>
                                                    <tr style={{float: 'right'}}>
                                                        <td>
                                                            <input type="text" id={"BASTIDOR" + 2} name="BASTIDOR" className="input" maxLength={6} onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} tabIndex="6" placeholder="Introducir nº bastidor" value={props.BASTIDOR || ''} onChange={updateState} size={20}/>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row>
                                </Table>
                            </tr>
                            </tbody>
                        </table>

                    </div>
                </Table>
                <div id="tabc01" className="tabcontent">
                    <div align="justify" className="tituloCabecera">
                        <h6>Información al cliente</h6>
                    </div>
                    <div align="justify" className="textoCabecera">
                        <img width={130} style={{float: 'right'}} src={require('../images/sobre.jpg')} alt='Imagen de sobre abierto'/>
                        {Parser(setTexto('info_cliente'))}
                        <a id="legal-link" target="_blank" rel="noopener noreferrer" tabIndex="0" href="https://www.veiasa.es/aviso_legal" onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)}> las condiciones recogidas en el aviso legal y política de privacidad de la web www.veiasa.es </a>.
                        <br/>
                    </div>
                    <div align="justify" className="textoCabecera" style={{display: 'none'}}>{Parser(setTexto('info_cliente_reducido'))}</div>
                    <div align="justify"><h6 style={{marginLeft: '0px'}}>Introduzca los datos de su vehículo:</h6></div>
                    <table style={{margin: 'auto'}}>
                        <tbody>
                        <tr>
                            <td>
                            </td>
                        </tr>
                        <tr>
                            <Table>
                                <Row>
                                    {BloqueMatricula(5)}
                                    {BloqueFechamBastidor(5)}
                                </Row>
                            </Table>
                        </tr>
                        <tr>
                            <div className="captchaDemoDiv">
                                {props.imagenCaptcha &&
                                    <img src={"data:image/png;base64," + props.imagenCaptcha}/>
                                }
                                <Table className="txt3s">
                                    <Row>
                                        <input type="text" id="TXT_CAPTCHA" name="TXT_CAPTCHA" value={props.TXT_CAPTCHA} onChange={updateState} onKeyDown={(event) => handleEnter(event, props.F_MAT, props.BASTIDOR)} className="input" maxLength={4} placeholder="Introducir captcha"/>
                                    </Row>
                                </Table>
                            </div>
                            <div className="citaSol botonera">
                                <input type="button" id={"btnSiguiente" + 5} value={setTexto('siguiente')} className="btn btn-primary boton" onClick={onSubmit} tabIndex="10"/>
                            </div>
                        </tr>
                        </tbody>
                    </table>
                </div>
                <div>
                    <p style={{paddingLeft: '35%'}}>{error}</p>
                    <p style={{paddingLeft: '35%'}}>{error_matricula}</p>
                </div>
                {info}
            </form>
        </Table>
    )
};

function setTabs(props) {
    setTab('0', props.opcion);
    setTab('1', props.opcion_p);
    setTab('2', props.opcion_mb);
    setTab('3', props.opcion_mb);
    setTab('4', props.opcion_mb);
    setTab('5', props.opcion_mb);
}

function setTab(tab, Id) {
    let i = 0;
    while (true) {
        let TAB = document.getElementById("tab" + tab + i);
        let TABC = document.getElementById("tabc" + tab + i);
        if (!TAB || !TABC) break;
        if (i === parseInt(Id)) {
            if (("tabc" + tab + i) === "tabc51") {
                TABC.style.display = 'flex';
            } else {
                TABC.style.display = 'block';
            }
            TAB.className = 'tablinks active'
        } else {
            TABC.style.display = 'none';
            TAB.className = 'tablinks'
        }
        i++;
    }
}

function registroAcceso(accesoGlobal, props) {
    let fechaIdentificador = new Date();
    if (accesoGlobal.identificador === '' || accesoGlobal.identificador === undefined || accesoGlobal.identificador === null) {
        props.SetTarget('fechaIdentificador', fechaIdentificador.getTime());
    } else {
        let diferencia = fechaIdentificador.getTime() - props.fechaIdentificador;
        let diferenciaEnMins = diferencia / (1000 * 60 * 60);
        if (diferenciaEnMins > 0.25) {
            accesoGlobal.identificador = "";
            props.SetTarget('fechaIdentificador', fechaIdentificador.getTime());
        }
    }
    webservice.registraAcceso(accesoGlobal).then(resp => {
        if (resp.resultado) {
            accesoGlobal.identificador = resp.resultado.identificador;
            props.SetTarget('identificador', accesoGlobal.identificador);
            props.SetTarget('plataforma', accesoGlobal.plataforma);
            props.SetTarget('navegador', accesoGlobal.navegador);
            props.SetTarget('imagenCaptcha', resp.resultado.imagenCaptcha);
            props.SetTarget('token', resp.resultado.token);
            cookie.save('token', resp.resultado.token, {path: '/'});
        }
    }).catch(error => {
        ShowSpin(false);
        SetError(constants.SET_ERROR, error);
    });
}

const mapStateToProps = state => {
    return {
        metodo: state.updatedState.metodo,
        identificador: state.updatedState.identificador,
        plataforma: state.updatedState.plataforma,
        navegador: state.updatedState.navegador,
        fechaIdentificador: state.updatedState.fechaIdentificador,
        show_info_mat: state.updatedState.show_info_mat,
        show_info_bastidor: state.updatedState.show_info_bastidor,
        show_calendario: state.updatedState.show_calendario,
        error_bastidor: state.updatedState.error_bastidor,
        error_matricula: state.updatedState.error_matricula,
        error: state.updatedState.error,
        idioma: state.updatedState.idioma,
        info: state.updatedState.info,
        show_spin: state.updatedState.show_spin,
        show_acceso: state.updatedState.show_acceso,
        LOCALIZADOR: state.updatedState.LOCALIZADOR,
        MATRICULA: state.updatedState.MATRICULA,
        MATRICULA_REMOLQUE: state.updatedState.MATRICULA_REMOLQUE,
        BASTIDOR: state.updatedState.BASTIDOR,
        l_remolque: state.updatedState.l_remolque,
        F_MAT: state.updatedState.F_MAT,
        f_mat: state.updatedState.f_mat,
        opcion_mb: state.updatedState.opcion_mb,
        opcion_p: state.updatedState.opcion_p,
        opcion: state.updatedState.opcion,
        show_eliminaOK: state.updatedState.show_eliminaOK,
        AUTORIZA: state.updatedState.AUTORIZA,
        UNIDADES: state.updatedState.UNIDADES,
        TIPOS_INSPECCIONES_REMOLQUE: state.updatedState.TIPOS_INSPECCIONES_REMOLQUE,
        TIPOS_INSPECCIONES: state.updatedState.TIPOS_INSPECCIONES,
        MINUTOS: state.updatedState.MINUTOS,
        prevPag: state.updatedState.prevPag,
        label: state.updatedState.label,
        TIP_INSP: state.updatedState.TIP_INSP,
        validacionCorreo: state.updatedState.validacionCorreo,
        mensaje: state.updatedState.mensaje,
        newPasswordModal: state.updatedState.newPasswordModal,
        REPEAT_PASSWORD: state.updatedState.REPEAT_PASSWORD,
        PASSWORD: state.updatedState.PASSWORD,
        claveModificada: state.updatedState.claveModificada,
        mensaje_ok: state.updatedState.mensaje_ok,
        sesionActiva: state.updatedState.sesionActiva,
        VISIBILIDAD: state.updatedState.VISIBILIDAD,
        modificaCita: state.updatedState.modificaCita,
        nombreUsuario: state.updatedState.nombreUsuario,
        titulo: state.updatedState.titulo,
        TXT_CAPTCHA: state.updatedState.TXT_CAPTCHA,
        imagenCaptcha: state.updatedState.imagenCaptcha
    };
};

const mapDispatchToProps = dispatch => {
    return {
        SetInfo(info) {
            dispatch(SetInfo(info));
        },
        ShowInfoMatBast(sMat, sBat) {
            dispatch(ShowInfoMatBast(sMat, sBat));
        },
        ShowCalendario(sCal) {
            dispatch(ShowCalendario(sCal));
        },
        SetError(type, error) {
            dispatch(SetError(type, error));
        },
        SetIdioma(idioma) {
            dispatch(SetIdioma(idioma));
        },
        ShowSpin(sSpin) {
            dispatch(ShowSpin(sSpin));
        },
        ShowAcceso(sAcc) {
            dispatch(ShowAcceso(sAcc));
        },
        SetTarget(tName, tValue) {
            dispatch(SetTarget(tName, tValue));
        },
        SetFMat(FMAT, fmat) {
            dispatch(SetFMat(FMAT, fmat));
        },
        ShowEliminaOK(sElimOK) {
            dispatch(ShowEliminaOK(sElimOK));
        },
        ChangeOpcMb(opcMb) {
            dispatch(ChangeOpcMb(opcMb));
        },
        ChangeOpcP(opcP) {
            dispatch(ChangeOpcP(opcP));
        },
        ChangeOpc(opc) {
            dispatch(ChangeOpc(opc));
        },
        SetState(state) {
            dispatch(SetState(state));
        },
        onComponentDidMount(props) {
            props.SetTarget('MATRICULA', '');
            props.SetTarget('BASTIDOR', '');
            props.SetTarget('F_MAT', '');
            props.SetTarget("TXT_CAPTCHA", "");
            props.SetError(constants.SET_ERROR, "");
            props.SetError(constants.SET_ERROR_MATRICULA, "");
            props.SetError(constants.SET_ERROR_BASTIDOR, "");
            setTabs(props);
            let accesoGlobal = {
                metodo: "InicioSesion",
                identificador: props.identificador,
                navegador: deviceDetect().browserName,
                plataforma: ""
            };
            if (isMobileSafari) {
                accesoGlobal.navegador = "Safari Mobile"
            }
            if (isMobile) {
                if (isChrome) {
                    accesoGlobal.navegador = "Chrome"
                }
                if (isIE) {
                    accesoGlobal.navegador = "IE"
                }
                if (isFirefox) {
                    accesoGlobal.navegador = "Firefox"
                }
            }
            if (props.plataforma !== "") {
                accesoGlobal.plataforma = props.plataforma;
            } else {
                let plataforma = "";
                if (isMobileOnly) {
                    plataforma = "Mobile";
                } else if (isTablet) {
                    plataforma = "Tablet"
                } else {
                    plataforma = "PC";
                }
                accesoGlobal.plataforma = plataforma;
            }
            registroAcceso(accesoGlobal, props);
        },

        onComponentWillMount(props) {
            let token = document.location.hash.replace("#/validaCorreo/?", "");
            if (props.fromCorreo) {
                webservice.validaCorreo(token).then(resp => {
                    if (resp.error !== undefined && resp.error !== '') {
                        let error = resp.error;
                        props.SetTarget('mensaje', error);
                        throw error;
                    }
                    if (resp.resultado) {
                        props.SetTarget('validacionCorreo', true);
                        props.SetTarget('mensaje', resp.resultado);
                    }
                }).catch(error => {
                    props.SetTarget('mensaje', error);
                });
            } else if (props.fromRecClave) {
                props.SetTarget('newPasswordModal', true);
            } else if (props.location.search) {
                props.history.push({pathname: './datosContacto', param: props.location.search})
            } else if (props.prevPag === 'datosContacto') {
                ClearState();
            }
        },
        ClearState() {
            dispatch(ClearState());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CitaSolicitud);
