const textos_idiomas = {
    // pagina principal - menu
    solicitar: ['Solicitar cita', 'Request a service', 'Fordern Sie einen Service an', 'Demander un service', 'Vraag een dienst aan'],
    contacto: ['Sus datos de contacto', 'Your contact information', 'Ihre Kontaktinformationen', 'Vos coordonnées', 'Uw contactgegevens'],
    modificar_solicitud: ['Modificar o anular cita', 'Modify or delete an application', 'Ändern oder löschen Sie eine Anwendung', 'Modifier ou supprimer une application', 'Wijzig of verwijder een applicatie'],
    cita_itv: ['Cita para ITV', 'Appointment for PTI', 'Termin für TIF', 'Rendez-vous pour ITV', 'Afspraak voor APK'],
    duplicado: ['Duplicado de tarjeta ITV', 'Duplicated ITV card', 'Duplizierte ITV-Karte', 'Carte ITV dupliquée', 'Gedupliceerde ITV-kaart'],
    reformas: ['Reformas con proyecto', 'Reforms with project', 'Reformen mit Projekt', 'Réformes avec le projet', 'Hervormingen met project'],
    // pagina principal - titulo campos
    iniciar_sesion: ['Iniciar sesión', 'Login', 'Einloggen', 'Commencer la session', 'Login'],
    cerrar_sesion: ['Cerrar sesión', 'Logout', 'Sitzung schließen', 'Fernmer la session', 'Sessie sluiten'],
    vehiculo: ['Vehículo', 'Vehicle', 'Fahrzeug', 'Véhicule', 'Voertuig'],
    remolque: ['Remolque', 'Trailer', 'Anhänger', 'Remorque', 'Aanhangwagen'],
    matricula: ['Matrícula', 'Plate number', 'Einschreibung', 'Plaque d\'inmatriculation', 'Kenteken'],
    fecha_mat: ['Fecha de matriculación', 'Registration date', 'Registrierungsdatum', 'Date d\'enregistrement', 'Datum eerste toelating'],
    entendido: ['Entendido', 'Understood', 'Verstanden', 'Compris', 'begrijpelijk'],
    graba_correcto: ['Los datos se han grabado correctamente',
        'The data has been recorded correctly',
        'Die Daten wurden korrekt aufgezeichnet',
        'Les données ont été correctement enregistrées',
        'De gegevens zijn correct opgeslagen'],
    bastidor: ['Nº de bastidor (6 últ. dígitos o completo si es menor de 6)', 'Vehicle identification number', 'Fahrzeugidentifikationsnummer', 'Numéro d\'identification du véhicule', 'Voertuig identificatie nummer'],
    solicitar_cita: ['Solicitar cita', 'Request an appointment', 'Fordern Sie einen Termin an', 'Demander un rendez-vous', 'Vraag een afspraak aan'],
    n_telefono: ['Nº de teléfono móvil', 'Phone number', 'Telefonnummer', 'Numéro de téléphone', 'Telefoonnummer'],
    email: ['Correo electrónico', 'E-Mail', 'E-Mail', 'E-Mail', 'E-Mail'],
    modificar: ['Modificar', 'Modify', 'Ändern', 'Modifier', 'Verandering'],
    registro_cliente: ['Aproveche las ventajas de registrarse como cliente',
        'Take advantage of registering as a customer',
        'Nutzen Sie die Registrierung als Kunde',
        'Profitez de l\'inscription en tant que client',
        'Maak gebruik van het registreren als klant'],
    forma_comunicacion: ['Por favor, indique la forma en la que desea que nos pongamos en contacto',
        'Please indicate the form in which you want us to get in touch',
        'Bitte geben Sie an, in welcher Form Sie uns erreichen möchten',
        'Veuillez indiquer le formulaire dans lequel vous souhaitez que nous vous contactions',
        'Geef aan in welk formulier u contact met ons op wilt nemen'],
    no_deseo_contacto: ['<b style="color:#00A8EA;">NO</b> deseo que se pongan en contacto conmigo para los servicios de notificación relacionados con la caducidad de la ITV de este vehículo',
        'I do not want you to contact me for notification services related to this vehicle',
        'Ich möchte nicht, dass Sie mich bezüglich der Benachrichtigungsdienste für dieses Fahrzeug kontaktieren',
        'Je ne veux pas que vous me contactiez pour les services de notification liés à ce véhicule',
        'Ik wil niet dat je contact met me opneemt voor meldingen met betrekking tot dit voertuig'],
    si_deseo_contacto: ['<b style="color:#00A8EA;">SI</b> deseo que se pongan en contacto conmigo para los servicios de notificación relacionados con el aviso de la caducidad de la ITV',
        'I do not want you to contact me for notification services related to this vehicle',
        'Ich möchte nicht, dass Sie mich bezüglich der Benachrichtigungsdienste für dieses Fahrzeug kontaktieren',
        'Je ne veux pas que vous me contactiez pour les services de notification liés à ce véhicule',
        'Ik wil niet dat je contact met me opneemt voor meldingen met betrekking tot dit voertuig'],

    confirma_no_contacto: ['Confirmo que no deseo recibir ninguna notificación relativa a mi vehículo, <b>lo que NO me exime de la obligación de pasar la ITV</b>',
        'I confirm that I do not wish to receive any notification regarding my vehicle, which does not exempt me from the obligation to pass the ITV',
        'Ich bestätige, dass ich keine Benachrichtigung über mein Fahrzeug erhalten möchte, was mich nicht von der Verpflichtung befreit, das ITV zu bestehen',
        'Je confirme que je ne souhaite recevoir aucune notification concernant mon véhicule, ce qui ne me dispense pas de l\'obligation de passer l\'ITV',
        'Ik bevestig dat ik geen enkele kennisgeving met betrekking tot mijn voertuig wens te ontvangen, wat mij niet vrijstelt van de verplichting om de ITV te behalen'],

    estacion: ['Estación', 'Station', 'Station', 'Station', 'Seizoen'],
    fecha: ['Fecha', 'Date', 'Datum', 'Date', 'Datum'],
    hora: ['Hora', 'Time', 'Zeit', 'Heure', 'Tijd'],
    tipo_vehiculo: ['Tipo de vehículo', 'Type of vehicle', 'Art des Fahrzeugs', 'Type de véhicule', 'Type voertuig'],
    tipo_inspeccion: ['Tipo de inspección', 'Type of inspection', 'Art der Inspektion', 'Type d\'inspection', 'Soort inspectie'],
    orden: ['Orden', 'Order', 'Bestellung', 'Commande', 'Order'],
    todas_estaciones: ['Todas las estaciones', 'All the stations', 'Alle Jahreszeiten', 'Toutes les stations', 'Alle seizoenen'],
    cambiar_fecha: ['Cambiar fecha', 'Change date', 'Datum ändern', 'Changer la date', 'Datum wijzigen'],
    cambiar_hora: ['Cambiar hora', 'Change time', 'Ändern Sie die Stunde', 'Changer l\'heure', 'Uur wijzigen'],
    cambiar_tipo: ['Cambiar tipo de inspección', 'Change type of inspection', 'Ändern Sie die Art der Inspektion', 'Changer le type d\'inspection', 'Wijzig het type inspectie'],
    localizador: ['Localizador', 'Locator', 'Locator', 'Localisateur', 'Pieper'],
    insp_remolque: ['Inspección con remolque', 'Inspection with trailer', 'Inspektion mit Anhänger', 'Inspection avec remorque', 'Inspectie met aanhangwagen'],
    fecha_formato: ['dd-mm-aaaa', 'dd-mm-yyyy', 'dd-mm-jjjj', 'dd-mm-yyyy', 'dd-mm-jjjj'],
    datos_veiasa: ['© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C/ Albert Einstein, 2. 41092 Sevilla | Teléfono de Atención al Cliente: 955 04 40 70',
        '© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C / Albert Einstein, 2. 41092 Seville | Customer Service Phone: 955 04 40 70',
        '© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C / Albert Einstein, 2. 41092 Sevilla | Kundendienst Telefon: 955 04 40 70',
        '© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C / Albert Einstein, 2. 41092 Séville | Service clientèle Téléphone: 955 04 40 70',
        '© Verificaciones Industriales de Andalucía, S.A. (VEIASA) | C / Albert Einstein, 2. 41092 Sevilla | Klantenservice Telefoon: 955 04 40 70'],
    siguiente: ['Siguiente', 'Next', 'Weiter', 'Suivant', 'volgend'],
    anterior: ['Anterior', 'Previous', 'Zurück', 'Précédent', 'voorgaand'],
    confirmar: ['Confirmar', 'Confirm', 'Bestätigen', 'Confirmer', 'Bevestigen'],
    acepto: ['Acepto las condiciones', 'I accept the conditions', 'Ich akzeptiere die Bedingungen', 'J\'accepte les conditions', 'Ik accepteer de voorwaarden'],
    legal_1: ['De conformidad con lo previsto en la Ley Orgánica 15/1999 de 13 de diciembre, sobre Protección de Datos de Carácter Personal y en el Real Decreto 1720/2007 que la desarrolla, le informamos que sus datos personales serán objeto de tratamiento automatizado por parte de Verificaciones Industriales de Andalucía, S.A. Los datos personales solicitados por Verificaciones Industriales de Andalucía, S.A. son recabados con la finalidad de gestionar la cita previa solicitada por usted para llevar a cabo la realización de la inspección técnica de su vehículo. Asimismo le informamos que el responsable del tratamiento de sus datos personales es la entidad ',
        'In accordance with the provisions of Organic Law 15/1999 of December 13, on Protection of Personal Data and Royal Decree 1720/2007 that develops it, we inform you that your personal data will be subject to automated processing by Verificaciones Industriales de Andalucía, SA The personal data requested by Verificaciones Industriales de Andalucía, S.A. They are collected in order to manage the previous appointment requested by you to carry out the technical inspection of your vehicle. We also inform you that the person responsible for the processing of your personal data is the entity',
        'In Übereinstimmung mit den Bestimmungen des Organgesetzes 15/1999 vom 13. Dezember, über den Schutz personenbezogener Daten und der Königlichen Verordnung 1720/2007, die es entwickelt, informieren wir Sie, dass Ihre persönlichen Daten von der automatisierten Verarbeitung durch Verificaciones Industriales de Andalucía, SA Die personenbezogenen Daten, die von Verificaciones Industriales de Andalucía, S.A. Sie werden gesammelt, um den vorherigen Termin zu verwalten, der von Ihnen für die technische Überprüfung Ihres Fahrzeugs verlangt wird. Wir informieren Sie auch, dass die Person, die für die Verarbeitung Ihrer persönlichen Daten verantwortlich ist, die Entität ist',
        'Conformément aux dispositions de la loi organique 15/1999 du 13 décembre, sur la protection des données personnelles et du décret royal 1720/2007 qui la développe, nous vous informons que vos données personnelles feront l\'objet d\'un traitement automatisé par Verificaciones Industriales de Andalucía, SA Les données personnelles demandées par Verificaciones Industriales de Andalucía, S.A. Ils sont collectés afin de gérer le rendez-vous antérieur demandé par vous pour effectuer l\'inspection technique de votre véhicule. Nous vous informons également que la personne responsable du traitement de vos données personnelles est l\'entité',
        'In overeenstemming met het bepaalde in de (Spaanse) Organieke Wet 15/1999 van 13 december inzake Bescherming Persoonsgegevens en het (Spaanse) Koninklijk Besluit 1720/2007 die deze wet verder uitwerkt, stellen wij u op de hoogte dat uw persoonlijke gegevens op geautomatiseerde wijze door Verificaciones Industriales de Andalucía, S.A. verwerkt zullen worden. De persoonsgegevens die door Verificaciones Industriales de Andalucía, S.A. opgevraagd en verzameld worden hebben als doel de afspraak, die u voor de technische keuring van uw voertuig hebt aangevraagd, te verwerken. Tevens informeren wij u dat het bedrijf dat voor de verwerking van uw persoonsgegevens verantwoordelijk is, de vennootschap Verificaciones Industriales de Andalucía, S.A. is.'],
    legal_2: ['Verificaciones Industriales de Andalucía, S.A., entidad ante la cual Vd. podrá ejercitar sus derechos de acceso, rectificación, cancelación u oposición al tratamiento de sus datos de carácter personal recogidos a través de este cuestionario, mediante escrito remitido por correo postal dirigido al Servicio de Atención al Cliente de Verificaciones Industriales de Andalucía, S.A., con la referencia "Protección de Datos" en su domicilio sito en la calle Albert Einstein, número 2, Isla de la Cartuja, 41092, Sevilla, o en la dirección de correo electrónico',
        'Verificaciones Industriales de Andalucía, SA, entity to which you may exercise your rights of access, rectification, cancellation or opposition to the processing of your personal data collected through this questionnaire, by writing sent by mail to the Service of Customer Service of Verificaciones Industriales de Andalucía, SA, with the reference "Data Protection" at your home located at Albert Einstein Street, number 2, Isla de la Cartuja, 41092, Seville, or at the email address',
        'Verificaciones Industriales de Andalucía, SA, Unternehmen, an dem Sie Ihre Rechte auf Zugang, Berichtigung, Löschung oder Widerspruch gegen die Verarbeitung Ihrer persönlichen Daten, die durch diesen Fragebogen erhoben werden, schriftlich per Post an den Dienst von Kundendienst von Verificaciones Industriales de Andalucía, SA, mit der Referenz "Datenschutz" bei Ihnen zu Hause in der Albert Einstein Straße, Nummer 2, Isla de la Cartuja, 41092, Sevilla, oder an der E-Mail-Adresse',
        'Verificaciones Industriales de Andalucía, SA, entité à laquelle vous pouvez exercer vos droits d\'accès, de rectification, annulation ou opposition au traitement de vos données personnelles recueillies par le biais de ce questionnaire, par écrit envoyé par courrier au Service de Service à la clientèle de Verificaciones Industriales de Andalucía, SA, avec la référence "Data Protection" à votre adresse située rue Albert Einstein, numéro 2, Isla de la Cartuja, 41092, Séville, ou à l\'adresse e-mail',
        'Tot deze vennootschap kunt u zich wenden voor het uitoefenen van uw recht op toegang tot deze gegevens, deze te verbeteren of te verwijderen of om de verwerking van uw persoonlijke gegevens, die via dit formulier verzameld worden, te weigeren, door middel van het sturen van een schrijven via de post naar de afdeling "Atención al cliente" (Klantenservice) van Verificaciones Industriales de Andalucía, S.A., onder vermelding van "Protección de Datos" (Bescherming Persoonsgegevens) naar het volgende adres: calle Albert Einstein, número 2, Isla de la Cartuja, 41092, Sevilla, of via email naar het volgende emailadres:'],
    info_mat: ['Introduzca la matrícula de su vehículo, sin usar espacios ni guiones. Únicamente emplee espacios en las antiguas matrículas provinciales de una sola letra para separar los números de la sigla provincial. Ejemplos: H 1234P GR1234KF 1234MNL.',
        'Enter the registration number of your vehicle, without using spaces or dashes. Only use spaces in the old provincial one-letter license plates to separate the numbers of the provincial acronym. Examples: H 1234P GR1234KF 1234MNL',
        'Geben Sie die Registrierungsnummer Ihres Fahrzeugs ein, ohne Leerzeichen oder Bindestriche zu verwenden. Verwenden Sie nur Leerzeichen in den alten provinziellen Ein-Buchstaben-Nummernschildern, um die Nummern der Provinz-Abkürzung zu trennen. Beispiele: H 1234P GR1234KF 1234MNL',
        'Entrez le numéro d\'immatriculation de votre véhicule, sans utiliser d\'espaces ou de tirets. N\'utilisez que des espaces dans les anciennes plaques d\'immatriculation provinciales à une lettre pour séparer les numéros de l\'acronyme provincial. Exemples: H 1234P GR1234KF 1234MNL',
        'Voer het kenteken van uw voertuig in, zonder spaties of streepjes te gebruiken. Gebruik alleen spaties in de oude provinciale eenletterige nummerplaten om de nummers van het provinciale acroniem te scheiden. Voorbeelden: H 1234P GR1234KF 1234MNL'],
    info: ['Aquí podrá obtener una cita para cualquier Estación ITV que gestione Veiasa. Para ello, podrá necesitar el permiso de circulación de su vehículo. Cada vez que tenga alguna duda sobre el dato a introducir y/o el formato en que debe hacerlo pulse el botón',
        'Here you can get an appointment for any ITV Station that Veiasa manages. For this, you may need the vehicle registration certificate. Whenever you have any doubts about the data to enter and / or the format in which you must do press the button',
        'Hier können Sie einen Termin für jede ITV-Station, die Veiasa verwaltet, erhalten. Dazu benötigen Sie möglicherweise das Fahrzeugschein-Zertifikat. Wenn Sie Zweifel haben, welche Daten eingegeben werden sollen und / oder in welchem Format Sie dies tun müssen, drücken Sie die Taste',
        'Ici vous pouvez obtenir un rendez-vous pour n\'importe quelle station ITV que Veiasa gère. Pour cela, vous pouvez avoir besoin du certificat d\'immatriculation du véhicule. Chaque fois que vous avez des doutes sur les données à saisir et / ou le format dans lequel vous devez faire, appuyez sur le bouton',
        'Hier kun je een afspraak krijgen voor elk ITV-station dat Veiasa beheert. Hiervoor hebt u mogelijk het kentekenbewijs nodig. Wanneer je twijfels hebt over de in te voeren gegevens en / of het formaat waarin je moet doen, druk je op de knop'],
    autoriza: ['¿Nos autoriza a utilizar su correo electrónico o número teléfono móvil para?',
        'Please indicate the aspects that authorize us to notify you',
        'Bitte geben Sie die Aspekte an, die uns autorisieren, Sie zu benachrichtigen',
        'Veuillez indiquer les aspects qui nous autorisent à vous informer',
        'Geef de aspecten aan die ons machtigen om u op de hoogte te stellen'],
    autoriza_2: ['Valoración del servicio',
        'Satisfaction survey',
        'Zufriedenheitsumfrage',
        'Enquête de satisfaction',
        'Tevredenheidsenquête  '],
    autoriza_3: ['Otra información relacionada con la empresa',
        'Services of interest related to ITV',
        'Dienstleistungen von Interesse in Bezug auf ITV',
        'Services d\'intérêt liés à ITV',
        'Diensten gerelateerd aan ITV'],
    info_reformas: ['Esta página le permite solicitar inspecciones no periódicas que requieren trámites administrativos. Podrá adjuntar la documentación en formato electrónico evitando desplazarse a nuestras instalaciones hasta que no haya sido tramitada',
        'This page allows you to request non-periodic inspections that require administrative procedures. You can attach the documentation in electronic format avoiding moving to our facilities until it has been processed',
        'Auf dieser Seite können Sie nicht periodische Inspektionen anfordern, die administrative Verfahren erfordern. Sie können die Dokumentation in elektronischer Form anhängen und vermeiden, bis zur Bearbeitung in unsere Einrichtungen zu gelangen',
        'Cette page vous permet de demander des inspections non périodiques nécessitant des procédures administratives. Vous pouvez joindre la documentation sous format électronique en évitant de déménager dans nos installations jusqu\'à ce qu\'elle ait été traitée',
        'Op deze pagina kunt u niet-periodieke inspecties aanvragen waarvoor administratieve procedures nodig zijn. U kunt de documentatie in elektronisch formaat bijvoegen en vermijden dat u naar onze faciliteiten gaat totdat deze is verwerkt'],
    notificacion: ['Notificación'],
    // language=HTML
    info_cliente: [
        'A partir de ahora las <b>comunicaciones</b> relativas a la Inspección Técnica de su Vehículo (ITV) se notificarán <b>únicamente a través de E-mail o SMS.</b>' +
        '<br/><br/>Con este nuevo sistema <b>dejará de recibir la tradicional carta de aviso</b>, donde se le indica cuándo le corresponde pasar la ITV a su vehículo.' +
        '<br/><br/>Por ello, <b>si quiere seguir recibiendo esta información</b> u otra de interés relativa al servicio, necesitamos que nos facilite su <b>dirección de correo electrónico y/o su número de teléfono móvil</b>, según ',
        'From now on, the <b> communications </b> related to the Technical Inspection of your Vehicle (ITV) will be notified <b> only through E-mail or SMS. </b> ' +
        '<br/> <br/> With this new system <b> you will no longer receive the traditional warning letter </b>, which tells you when it is appropriate to pass the ITV to your vehicle.' +
        '<br/> <br/> Therefore, <b> if you want to continue receiving this information </b> or another one of interest related to the service, we need you to provide us with your <b> email address and / or your number of mobile phone </b>, according to the conditions included in this link. ' +
        '<br/> <br/> Veiasa agrees not to send you advertising <br/>',
        'Ab jetzt wird die <b> Kommunikation </b> zur Technischen Inspektion Ihres Fahrzeugs (ITV) <b> nur per E-Mail oder SMS mitgeteilt. </b> ' +
        '<br/> <br/> Mit diesem neuen System <b> erhalten Sie nicht mehr den traditionellen Warnbrief </b>, der Ihnen mitteilt, wann es angemessen ist, den ITV an Ihr Fahrzeug zu übergeben.' +
        '<br/> <br/> Wenn Sie also weiterhin diese Informationen erhalten möchten oder eine andere von Interesse, die für den Service von Interesse ist, müssen Sie uns Ihre <b> E-Mail-Adresse und / oder Ihre Nummer mitteilen. </b> des Mobiltelefons </b> gemäß den Bedingungen in diesem Link. ' +
        '<br/> <br/> Veiasa stimmt zu, dir keine Werbung zu schicken <br/>  ',
        'A partir de maintenant, les <b> communications </b> relatives à l\'inspection technique de votre véhicule (ITV) ne seront notifiées <b> que par e-mail ou par SMS. </b> ' +
        '<br/> <br/> Avec ce nouveau système <b>, vous ne recevrez plus la lettre d\'avertissement traditionnelle </b>, qui vous indique quand il est approprié de transmettre l\'ITV à votre véhicule.' +
        '<br/> <br/> Par conséquent, <b> si vous souhaitez continuer à recevoir cette information </b> ou une autre d\'intérêt lié au service, nous avons besoin que vous nous fournissiez votre <b> adresse e-mail et / ou votre numéro de téléphone portable </b>, selon les conditions incluses dans ce lien. ' +
        '<br/> <br/> Veiasa accepte de ne pas vous envoyer de publicité <br/>',
        'Vanaf nu worden de <b> communicatie </b> met betrekking tot de technische inspectie van uw voertuig (ITV) <b> alleen via e-mail of sms gemeld. </b> ' +
        '<br/> <br/> Met dit nieuwe systeem <b> ontvangt u niet langer de traditionele waarschuwingsbrief </b>, die u vertelt wanneer het gepast is om de ITV aan uw voertuig door te geven.' +
        '<br/> <br/> Daarom <b> als u deze informatie wilt blijven ontvangen </b> of een andere interesse wilt hebben voor de service, moeten wij u uw <b> e-mailadres en / of uw nummer verstrekken van mobiele telefoon </b>, volgens de voorwaarden opgenomen in deze link. ' +
        '<br/> <br/> Veiasa stemt ermee in om je geen advertenties te sturen <br/>'],


    info_cliente_reducido: [
        'A partir de ahora las <b>comunicaciones</b> relativas a la Inspección Técnica de su Vehículo (ITV) se notificarán <b>únicamente a través de E-mail o SMS.</b>' +
        'No volveremos a enviarle carta de aviso de la ITV.' +
        '<b>Si desea que le sigamos avisando de cuándo le caduca la ITV</b> de su vehículo, déjenos sus datos de contacto. <a href="https://www.veiasa.es/aviso_legal">Aviso Legal y Política de Privacidad </a> de Veiasa.<br/>',
        'From now on, the <b> communications </b> related to the Technical Inspection of your Vehicle (ITV) will be notified <b> only through E-mail or SMS. </b> ' +
        '<br/> <br/> With this new system <b> you will no longer receive the traditional warning letter </b>, which tells you when it is appropriate to pass the ITV to your vehicle.' +
        '<br/> <br/> Therefore, <b> if you want to continue receiving this information </b> or another one of interest related to the service, we need you to provide us with your <b> email address and / or your number of mobile phone </b>, according to the conditions included in this link. ' +
        '<br/> <br/> Veiasa agrees not to send you advertising <br/>',
        'Ab jetzt wird die <b> Kommunikation </b> zur Technischen Inspektion Ihres Fahrzeugs (ITV) <b> nur per E-Mail oder SMS mitgeteilt. </b> ' +
        '<br/> <br/> Mit diesem neuen System <b> erhalten Sie nicht mehr den traditionellen Warnbrief </b>, der Ihnen mitteilt, wann es angemessen ist, den ITV an Ihr Fahrzeug zu übergeben.' +
        '<br/> <br/> Wenn Sie also weiterhin diese Informationen erhalten möchten oder eine andere von Interesse, die für den Service von Interesse ist, müssen Sie uns Ihre <b> E-Mail-Adresse und / oder Ihre Nummer mitteilen. </b> des Mobiltelefons </b> gemäß den Bedingungen in diesem Link. ' +
        '<br/> <br/> Veiasa stimmt zu, dir keine Werbung zu schicken <br/>  ',
        'A partir de maintenant, les <b> communications </b> relatives à l\'inspection technique de votre véhicule (ITV) ne seront notifiées <b> que par e-mail ou par SMS. </b> ' +
        '<br/> <br/> Avec ce nouveau système <b>, vous ne recevrez plus la lettre d\'avertissement traditionnelle </b>, qui vous indique quand il est approprié de transmettre l\'ITV à votre véhicule.' +
        '<br/> <br/> Par conséquent, <b> si vous souhaitez continuer à recevoir cette information </b> ou une autre d\'intérêt lié au service, nous avons besoin que vous nous fournissiez votre <b> adresse e-mail et / ou votre numéro de téléphone portable </b>, selon les conditions incluses dans ce lien. ' +
        '<br/> <br/> Veiasa accepte de ne pas vous envoyer de publicité <br/>',
        'Vanaf nu worden de <b> communicatie </b> met betrekking tot de technische inspectie van uw voertuig (ITV) <b> alleen via e-mail of sms gemeld. </b> ' +
        '<br/> <br/> Met dit nieuwe systeem <b> ontvangt u niet langer de traditionele waarschuwingsbrief </b>, die u vertelt wanneer het gepast is om de ITV aan uw voertuig door te geven.' +
        '<br/> <br/> Daarom <b> als u deze informatie wilt blijven ontvangen </b> of een andere interesse wilt hebben voor de service, moeten wij u uw <b> e-mailadres en / of uw nummer verstrekken van mobiele telefoon </b>, volgens de voorwaarden opgenomen in deze link. ' +
        '<br/> <br/> Veiasa stemt ermee in om je geen advertenties te sturen <br/>'],


    info_cliente_pie: ['<i><h6>Verificaciones Industriales de Andalucía, S.A. (VEIASA) tratará la información que nos facilite para enviarle comunicaciones relativas a la ITV de su vehículo, como el aviso de caducidad de su ITV, en base a su consentimiento. No se cederán datos a terceros, salvo obligación legal. Tiene derecho a acceder, rectificar y suprimir los datos, solicitar la portabilidad, oponerse al tratamiento y solicitar su limitación. Más información en nuestro <a href="https://www.veiasa.es/aviso_legal">aviso legal y política de privacidad</a>.</h6></i>'],
    info_solicitar_cita_pie: ['<i><h6>Verificaciones Industriales de Andalucía, S.A. (VEIASA) tratará la información que nos facilite con el fin de gestionar la Inspección Técnica de Vehículos en base a la ejecución de la prestación de servicios solicitada. En cumplimiento del Real Decreto 920/2017, de 23 de octubre, por el que se regula la inspección técnica de vehículos, se facilitará una copia impresa del informe de la inspección a la persona que presente el vehículo a inspección, una vez firmado, y el resultado de las inspecciones al Registro de Vehículos de la Jefatura Central de Tráfico. El informe de inspección será conservado durante al menos cinco años. Es necesario que complete los datos marcados como obligatorios para solicitar la Inspección Técnica de Vehículos. Puede ejercitar sus derechos, a presentar una reclamación ante una autoridad de control, de acceso, rectificación, supresión y portabilidad de sus datos, de limitación y oposición a su tratamiento, cuando procedan, ante VEIASA, calle Albert Einstein, 2, Isla de la Cartuja, C.P. 41092, Sevilla, o en lopd@veiasa.es. Más información en el aviso legal de la web www.veiasa.es</h6></i>'],
    confirmar_rgpd_1: ['El proceso ha finalizado correctamente.'],
    confirmar_rgpd_2: ['Los datos para la notificación de la caducidad de la ITV son: '],
    confirmar_rgpd_3: ['A partir de ahora recibirá las notificaciones de caducidad de su vehículo a través de '],
    confirmar_rgpd_4: ['. No recibirá más cartas.' +
    '<br/><br/>Recuerde que puede consultar, modificar o actualizar estos datos en la web, www.veiasa.es, en el apartado “Servicio de Notificaciones”.' +
    '<br/><br/>Muchas gracias por su colaboración']
};

export default textos_idiomas;