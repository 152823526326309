import {keyUnica, urlWS} from "./urls";

function URL(path) {
    return urlWS + path;
}

function customFetch(path, data) {
    return fetch(URL(path), data)
        .then(resp => {
            if (resp.status !== 200) {
                let error = new Error(resp.statusText);
                error.response = resp;
                throw error;
            }
            return resp.json();
        })
        .catch(error => {
            console.error("Error en el webservice: ", error);
            return ({ERROR: 'Se ha producido una incidencia en el servicio Web: ' + error})
        });
}

function generic_json(method, data, token) {
    let config; //= {};
    if (token != null) {
        config = {
            method: method,
            headers: {'Content-Type': 'application/json', 'Authorization': keyUnica, 'Auth2': token}
        };
    } else {
        config = {
            method: method,
            headers: {'Content-Type': 'application/json', 'Authorization': keyUnica}
        };
    }
    if (method !== 'GET' && method !== 'HEAD') {
        config['body'] = JSON.stringify(data);
    }
    return config;
}

const webservice = {
    autorizarCita: (token, datos_store, matricula, bastidor, f_mat, acceso) => {
        let data = generic_json('POST', {matricula: matricula, bastidor: bastidor, f_mat: f_mat, acceso: acceso}, token);
        return customFetch('citasweb/autorizarCita', data)
    },
    registraAcceso: (acceso) => {
        let data = generic_json('POST', {acceso});
        return customFetch('citasweb/registraAcceso', data)
    },
    eliminaCita: (matricula, localizador, l_origen, token) => {
        let data = generic_json('POST',
            {matricula: matricula, localizador: localizador, l_origen, token});
        return customFetch('citasweb/eliminaCita', data)
    },
    diasDisponibles: (token, unidad, tipo_i, tipo_i_c) => {
        let data = generic_json('POST', {unidad, tipo_i, tipo_i_c},
            token);
        return customFetch('citasweb/diasDisponibles', data)
    },
    horasDisponibles: (token, fecha_cita) => {
        let data = generic_json('POST',
            {fecha_cita}, token);
        return customFetch('citasweb/horasDisponibles', data)
    },
    acceso: (correo, clave) => {
        let data = generic_json('POST',
            {correo: correo, clave: clave});
        return customFetch('citasweb/acceso', data)
    },
    grabaClienteAutoriza: (token, datos_store, acceso) => {
        let AUTORIZA = datos_store.AUTORIZA;
        let telefono = AUTORIZA.telefono; // primero pone el campo seleccionado de la base de datos
        if (AUTORIZA.lcl_TELEFONO) // si existe el campo de pantalla input, lo pone
            telefono = AUTORIZA.lcl_TELEFONO;
        let correo_e = AUTORIZA.correo_e;
        if (AUTORIZA.lcl_CORREO_E)
            correo_e = AUTORIZA.lcl_CORREO_E;
        let detalle = AUTORIZA.detalle;
        let data = generic_json('POST', {
            matricula: datos_store.MATRICULA,
            telefono,
            correo_e,
            detalle,
            acceso: acceso
        }, token);
        return customFetch('citasweb/grabaClienteAutoriza', data)
    },
    grabaClienteAutorizaError: (token) => {
        let data = generic_json('POST',
            {entrada: token});
        return customFetch('citasweb/grabaClienteAutorizaError', data)
    },
    obtieneDatosPrerellenados: (entrada) => {
        let data = generic_json('POST',
            {entrada});
        return customFetch('citasweb/obtieneDatosPrerellenados', data)
    },
    altaUsuario: (correo, clave, nombre, token) => {
        let data = generic_json('POST',
            {correo, clave, nombre, token});
        return customFetch('citasweb/altaUsuario', data)
    },
    validaCorreo: (token) => {
        let data = generic_json('POST',
            {token});
        return customFetch('citasweb/validaCorreo', data)
    },
    solicitarClave: (correo) => {
        let data = generic_json('POST',
            {correo});
        return customFetch('citasweb/solicitarClave', data)
    },
    modificaCita: (matricula, localizador, token) => {
        let data = generic_json('POST',
            {matricula, localizador, token});
        return customFetch('citasweb/modificaCita', data)
    },
    IUDFunction: (token, tabla, datos, operacion) => {
        if (tabla === 'ROLES') {
            let data = generic_json('POST', {dRol: datos, operacion}, token);
            return customFetch('citasweb/IUDRoles', data)
        } else if (tabla === 'VEHICULOS') {
            let data = generic_json('POST', {dVehiculo: datos, operacion}, token);
            return customFetch('citasweb/IUDVehiculos', data)
        } else if (tabla === 'USUARIOS') {
            let data = generic_json('POST', {dUsuario: datos, operacion}, token);
            return customFetch('citasweb/IUDUsuarios', data)
        } else if (tabla === 'HORARIOS') {
            let data = generic_json('POST', {dHorario: datos, operacion}, token);
            return customFetch('citasweb/IUDHorarios', data)
        } else if (tabla === 'RESERVAS') {
            let data = generic_json('POST', {dReserva: datos, operacion}, token);
            return customFetch('citasweb/IUDReservas', data)
        }
    },
};

export default webservice;
