import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react';
import ReactDOM from 'react-dom';
import './css/index.css';
import {unregister} from './utils/registerServiceWorker';
import {Provider} from 'react-redux';
import {HashRouter as Router, Route, Switch} from 'react-router-dom'
import DatosContacto from "./containers/DatosContacto";
import CitaSolicitud from "./containers/CitaSolicitud";
import {persistor, store} from './utils/reducers';
import {ClearState, SetTarget} from './utils/actions';
import {PersistGate} from 'redux-persist/integration/react';
import ErrorBoundary from 'react-error-boundary';
import TituloNavegacion from './components/tituloNavegacion';
import 'bootstrap/dist/css/bootstrap.css';
import './css/pie.css';
import Cabecera from './components/Cabecera';
import Pie from './components/Pie';
import {Row, Table} from 'react-responsive-table';
import 'core-js';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/number/is-nan';
import 'core-js/modules/es.symbol';

window.addEventListener('pageshow', () => {
    // if (!document.location.hash.includes('#/sms')) {
    //     store.dispatch(ClearState());
    // }
    if (!document.location.hash === '/') {
        store.dispatch(ClearState());
    }
    if (document.location.hash !== "#/" && !document.location.hash.includes('#/sms')) {
        document.location.href = "/";
    }
});

// window.addEventListener("pagehide", (ev) => {
//     window.localStorage.setItem('refreshedPage', document.location.hash);
// });
window.addEventListener("onunload", (ev) => {
    ClearState();
    
});

window.addEventListener("beforeunload", (ev) => {
    window.localStorage.clear();
    if (document.location.hash === "#/") {
        store.dispatch(ClearState());
        return;
    }
    if (store.getState().updatedState.errorPage) {
        store.dispatch(SetTarget('errorPage', false));
        return;
    }
    if (store.getState().updatedState.sms || document.location.hash.includes('#/solicitarClave') || document.location.hash.includes('#/validaCorreo')) {
        store.dispatch(ClearState());
        return;
    }
});

const errorHandler = (e) => {
    store.dispatch(SetTarget('errorPage', true));
    document.location.href = "/";
    console.log(e)
};

const FallbackComponent = () => (
    <div>
    </div>
);

unregister();
ReactDOM.render(
    <Provider store={store}>
        <div id="mainContainer" style={{height: '100%'}}>
            <Table>
                <Row><Cabecera/></Row>
                <Row>
                    <main>
                        <PersistGate loading={null} persistor={persistor}>
                            <TituloNavegacion/>
                            <ErrorBoundary FallbackComponent={FallbackComponent} onError={errorHandler}>
                                <Router>
                                    <Switch>
                                        <Route exact path="/citaSolicitud" component={CitaSolicitud}/>
                                        <Route exact path="/datosContacto" component={DatosContacto}/>
                                        <Route path="/sms/" component={() => <DatosContacto fromAcceso={true}/>}/>
                                        <Route path="/validaCorreo/" component={() => <CitaSolicitud fromCorreo={true}/>}/>
                                        <Route path="/solicitarClave/" component={() => <CitaSolicitud fromRecClave={true}/>}/>
                                        <Route component={CitaSolicitud}/>
                                    </Switch>
                                </Router>
                            </ErrorBoundary>
                        </PersistGate>
                    </main>
                </Row>
            </Table>
            <Table>
                <Pie/>
            </Table>
        </div>
    </Provider>
    ,
    document.getElementById('root')
);



