import React from 'react';
import connect from 'react-redux-connect-lifecycle';
import ReactModal from "react-modal";
import {Circle} from 'better-react-spinkit';


const Spinner = (props) => {

    const estilosSpin
        = {
        content: {
            top: '50%',
            bottom: 'auto',
            left: '40%',
            right: 'auto',
            width: 200,
            height: 200,
            background: 'none',
            border: 3
        }
    };

    return (
        <ReactModal isOpen={props.isOpen} style={estilosSpin}>
            <div align="center">
                <Circle color={"#00A8EA"} size={100}/>
            </div>
        </ReactModal>
    );
};

const mapStateToProps = state => {
    return {
        isOpen: state.updatedState.isOpen
    }
};
export default connect(mapStateToProps, null)(Spinner);