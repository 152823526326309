import urls from './ini.json';
import nombre from '../../package.json';
// Prueba
let i;
let HOST;
let nombreApp=nombre.name;
let KEYUNICA = undefined;

const hostname = window && window.location && window.location.hostname;

for( i = 0; i < urls.length; i++ ) {
    if(hostname === urls[i].host && nombreApp===urls[i].name) {
        HOST = urls[i].url;
        KEYUNICA = urls[i].key.toString();
        break;
    }
}

export const urlWS = `${HOST}`;
export const keyUnica = KEYUNICA;
export const version = nombre.version;