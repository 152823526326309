import React from 'react';
import connect from "react-redux-connect-lifecycle";
import {Row, Table} from 'react-responsive-table';

const tituloNavegacion = () => {
    return (
        <Table>
            <Row>
                <div className="tituloCabecera" align="justify" style={{width: '100%', height: '70px'}}>
                    <h1 style={{paddingLeft: '0px', paddingTop: '0px', paddingBottom: '0px'}}> Servicio de notificaciones</h1>
                    <div>VEIASA</div>
                </div>
            </Row>
        </Table>
    );
};

const mapStateToProps = state => {
    return {
        titulo: state.updatedState.titulo
    };
};

export default connect(mapStateToProps, null)(tituloNavegacion);