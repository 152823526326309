import {combineReducers, createStore, compose} from 'redux';
import constants from "./constants";
import moment from 'moment';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';


export const initialState = {

    metodo: '',
    identificador: '',
    plataforma: '',
    navegador: '',
    fechaIdentificador:'',
    error: '',
    error_matricula: '',
    error_bastidor: '',
    info: '',
    idioma: 0,
    txtModal: '',
    showModal: false,
    show_info_mat: false,
    show_info_bastidor: false,
    show_calendario: false,
    show_spin: false,
    show_acceso: false,
    show_horas: false,
    show_pagoTarjeta: false,
    show_grabaOK: false,
    bgColor: '#fff',
    value: '',
    descrip: '',
    opcion: '1',
    opcion_p: '0',
    opcion_mb: '0',
    lcl_chk_telefono: false,
    lcl_chk_correo_e: false,
    D1: false,
    D2: false,
    pagina_siguiente: '',
    F_MAT: '',
    f_mat: moment('', 'DD-MM-YYYY'),
    seconds: 5,
    FECHA_CITA: '',
    HORA_CITA: '',
    l_aceptar: false,
    l_remolque: false,
    time: {},
    TIPO_INSP: '0',
    TIPO_INSP_REMOLQUE: '0',
    tipo_insp_np_remolque: '',
    tipo_insp_np: '',
    TXT_UNIDAD: '',
    l_todas_provincias: false,
    ConexflowDatos: {},
    UNIDAD: '',
    provincia: 11,
    unidadesCombo: [],
    horasDisponibles: [],
    X_HUECO: 0,
    poblaciones: [{value: 0, text: ''}],
    MATRICULA: '',
    LOCALIZADOR: '',
    MATRICULA_REMOLQUE: '',
    BASTIDOR: '',
    show_eliminaOK: false,
    lcl_chk_ninguna: false,
    lcl_chk_alguna: false,
    lcl_CORREO_E: '',
    lcl_chk_modifica_correo_e: false,
    lcl_CORREO_E_repetido: '',
    lcl_TELEFONO: '',
    lcl_chk_modifica_telefono: false,
    lcl_chk_ninguna_confirma: false,
    show_telefono: false,
    show_correo_e: false,
    InfoTimer: '',
    TXT_TIPO_INSP: '',
    TXT_TIPO_INSP_REMOLQUE: '',
    diasDisponibles: {},
    TOKEN: null,
    AUTORIZA: null,
    UNIDADES: null,
    TIPOS_INSPECCIONES: [],
    TIPOS_INSPECCIONES_REMOLQUE: [],
    fecha_cita: moment('', 'DD-MM-YYYY'),
    MINUTOS: 5,
    TIPO_VEHICULO: '',
    prevPag: '',
    errorPage: false,
    solicitaCita: false,
    eliminaCita: false,
    FECHA: moment().format('DD-MM-YYYY'),
    modo_acceso: '1',
    CORREO: '',
    CLAVE: '',
    DatosMisVehiculos: {},
    label: 'Periódica',
    showNewUserModal: false,
    NOMBRE: '',
    sms: false,
    activeTab: '2',
    ORDEN: '',
    ORDEN_C: '',
    unidad_seleccionada: '',
    PASSWORD: '',
    REPEAT_PASSWORD: '',
    mensaje: '',
    creacionCorrecta: '',
    validacionCorreo: false,
    show_password_modal: false,
    correoEnviado: false,
    newPasswordModal: false,
    mensaje_ok: '',
    claveModificada: false,
    sesionActiva: false,
    VISIBILIDAD: {},
    nombreUsuario: '',
    modalSpin: false,
    citas: [{}],
    F_CUADRANTE: '',
    fechaCuadrante: moment('', 'DD-MM-YYYY'),
    hayDatos: false,
    noPropuesta: false,
    confirmOption: '-1',
    NUM_CITA: '',
    TELEFONO: '',
    telefono_c: '',
    correo_e_c: '',
    isOpen: false,
    PAGINAS: 0,
    paginaActual: 1,
    modificaCita: false,
    CORREO_E: '',
    adminActiveTab: '1',
    CRUD: null,
    newData: {},
    CRUDmodified: {},
    X_TRAB: null,
    PERIODO: '',
    L_TIPO: '',
    buscaCitaOculto: false,
    numCitaList: [],
    citaSeleccionada: '',
    datosCitaOculto: true,
    datosCita: null,
    titulo: 'notificaciones',
    lcl_chk_no_propietario: false,
    showDesvincula: false,
    sinRegistro: false,
    modificandoTlfContacto: false,
    modificandoEmailContacto: false,
    emailEnviado: '',
    startDate: '',
    endDate: '',
    modalNuevoVehiculo: false,
    F_CAD: '',
    ANNOS: '',
    MARCA: '',
    unidad_conta: '',
    periodo: '',
    n_tique: '',
    matricula_bastidor: '',
    texto_matricula_bastidor: '',
    captcha: false,
    showSpin: '',
    x_apunte: null,
    cif: null,
    texto: '',
    show_condiciones: false,
    imagen_elegida: '',
    imagenes_sel: [],
    imagen_seleccionada: null,
    textoCaptcha: '',
    cargaCaptcha: false
};


function updatedState(state = initialState, action) {
    switch (action.type) {
        case constants.SET_ERROR:
            return {...state, error: action.error};
        case constants.SET_ERROR_MATRICULA:
            return {...state, error_matricula: action.error};
        case constants.SET_ERROR_BASTIDOR:
            return {...state, error_bastidor: action.error};
        case constants.SET_TARGET:
            let res = {...state};
            let name = action.targetName;
            let value = action.targetValue;
            res[name] = value;
            return res;
        case constants.CLEAR_STATE:
            let newState2 = initialState;
            newState2.metodo = state.metodo;
            newState2.identificador = state.identificador;
            newState2.plataforma = state.plataforma;
            newState2.navegador = state.navegador;
            newState2.fechaIdentificador=state.fechaIdentificador;
            return initialState;
        case constants.CLEAR_PARTIAL_STATE:
            let newState = initialState;
            newState.TIPOS_INSPECCIONES_REMOLQUE = state.TIPOS_INSPECCIONES_REMOLQUE;
            newState.TIPOS_INSPECCIONES = state.TIPOS_INSPECCIONES;
            newState.UNIDADES = state.UNIDADES;
            newState.sesionActiva = state.sesionActiva;
            newState.nombreUsuario = state.nombreUsuario;
            newState.VISIBILIDAD = state.VISIBILIDAD;
            newState.fechaIdentificador=state.fechaIdentificador;
            return newState;
        case constants.SET_STATE:
            let estado = action.state;
            for (var key in estado) {
                state[key] = estado[key];
            }
            return state;
        case constants.SET_IDIOMA:
            return {...state, idioma: action.idioma};
        case constants.SET_TOKEN:
            return {...state, TOKEN: action.token};
        case constants.SET_TIPO_INSP:
            return {...state, TIPO_INSP: action.tipInsp};
        case constants.SET_TIPO_INSP_REMOLQUE:
            return {...state, TIPO_INSP_REMOLQUE: action.tipInsp};
        case constants.SET_TIPOS_INSPECCIONES:
            return {...state, TIPOS_INSPECCIONES: action.tiposInsp};
        case constants.SET_TIPOS_INSPECCIONES_REMOLQUES:
            return {...state, TIPOS_INSPECCIONES_REMOLQUES: action.tiposInsp};
        case constants.SET_AUTORIZA:
            return {...state, AUTORIZA: action.autoriza};
        case constants.SET_UNIDADES_TOKEN:
            return {...state, UNIDADES: action.unidades};
        case constants.SHOW_INFO_MATBAST:
            return {...state, show_info_mat: action.show_info_mat, show_info_bastidor: action.show_info_bastidor};
        case constants.SHOW_CALENDARIO:
            return {...state, show_calendario: action.show_calendario};
        case constants.SHOW_SPIN:
            return {...state, show_spin: action.show_spin};
        case constants.SHOW_TELEFONO:
            return {...state, show_telefono: action.show_telefono};
        case constants.SHOW_CORREO_E:
            return {...state, show_correo_e: action.show_correo_e};
        case constants.SET_INFO:
            return {...state, info: action.info};
        case constants.SET_DESCRIPCION:
            return {...state, descrip: action.descrip};
        case constants.SET_LOCALIZADOR:
            return {...state, LOCALIZADOR: action.localizador};
        case constants.SET_TIPO_VEHICULO:
            return {...state, TIPO_VEHICULO: action.tipVehiculo};
        case constants.ELEMENT_SELECTED:
            if (action.value)
                return {...state, bgColor: action.bgColor, value: action.value};
            else
                return {...state, bgColor: action.bgColor};
        case constants.SHOW_ACCESO:
            return {...state, show_acceso: action.show_acceso};
        case constants.SHOW_HORAS:
            return {...state, show_horas: action.show_horas};
        case constants.SHOW_PAGO_TARJETA:
            return {...state, show_pagoTarjeta: action.show_pagoTarjeta};
        case constants.SHOW_GRABA_OK:
            return {...state, show_grabaOK: action.show_grabaOK};
        case constants.SHOW_ELIMINA_OK:
            return {...state, show_eliminaOK: action.show_eliminaOK};
        case constants.MODAL:
            return {...state, txtModal: action.txtModal, showModal: action.showModal};
        case constants.SEL_TAB:
            return {...state, opcion: action.opcion};
        case constants.SEL_TAB_P:
            return {...state, opcion_p: action.opcion_p};
        case constants.SEL_TAB_MB:
            return {...state, opcion_mb: action.opcion_mb};
        case constants.UPDATE_CHK_DAT_CONTACTO:
            return {
                ...state,
                lcl_chk_telefono: action.lcl_chk_telefono,
                lcl_chk_correo_e: action.lcl_chk_correo_e,
                D1: action.D1,
                D2: action.D2
            };
        case constants.PAGINA_SIGUIENTE:
            return {...state, pagina_siguiente: action.pagina_siguiente};
        case constants.SET_F_MAT:
            if (action.moment)
                return {...state, f_mat: moment(action.f_mat, 'DD-MM-YYYY'), F_MAT: action.f_mat};
            else
                return {...state, F_MAT: action.f_mat};
        case constants.SET_SECONDS:
            return {...state, seconds: action.seconds};
        case constants.SET_CITA:
            return {...state, FECHA_CITA: action.FECHA_CITA, HORA_CITA: action.HORA_CITA, fecha_cita: action.moment};
        case constants.SET_L_ACEPTAR:
            return {...state, l_aceptar: action.l_aceptar};
        case constants.SET_L_REMOLQUE:
            return {...state, l_remolque: action.l_remolque};
        case constants.SET_INFOTIMER:
            return {...state, InfoTime: action.InfoTime};
        case constants.SET_TIPO_INS_NP:
            return {...state, tipo_insp_np: action.value};
        case constants.SET_TIPO_INS_NP_REMOLQUE:
            return {...state, tipo_insp_np_remolque: action.value};
        case constants.SET_TXT_UNIDAD:
            return {...state, TXT_UNIDAD: action.text};
        case constants.SET_TXT_TIPO_INSP:
            return {...state, TXT_TIPO_INSP: action.text};
        case constants.SET_TXT_TIPO_INSP_REMOLQUE:
            return {...state, TXT_TIPO_INSP_REMOLQUE: action.text};
        case constants.SET_L_TODAS_PROVINCIAS:
            return {...state, l_todas_provincias: action.l_todas_provincias};
        case constants.SET_CONEXFLOW:
            return {...state, ConexflowDatos: action.conexflow};
        case constants.SET_UNIDAD:
            return {...state, UNIDAD: action.unidad};
        case constants.SET_PROVINCIA:
            return {...state, provincia: action.provincia};
        case constants.SET_UNIDADES_COMBO:
            return {...state, unidadesCombo: action.unidadesCombo};
        case constants.SET_HORAS_DISPONIBLES:
            return {...state, horasDisponibles: action.horasDisponibles};
        case constants.SET_DIAS_DISPONIBLES:
            return {...state, diasDisponibles: action.diasDisponibles};
        case constants.SET_HUECO:
            return {...state, X_HUECO: action.X_HUECO};
        case constants.SET_POBLACIONES:
            return {...state, poblaciones: action.poblaciones};
        case constants.SET_TOKEN_INFO:
            return {...state, INFO: action.info};
        case constants.SET_ORDEN:
            return {...state, ORDEN: action.orden};
        case constants.SET_MATRICULA:
            return {...state, MATRICULA: action.matricula};
        case constants.SET_FLAG_SOLICITA:
            return {...state, solicitaCita: action.solicitaCita};
        case constants.SET_FLAG_ELIMINA:
            return {...state, eliminaCita: action.eliminaCita};
        case constants.SET_VISIBILIDAD:
            return {...state, VISIBILIDAD: action.dVisibilidad};
        case constants.SET_FECHA:
            return {...state, FECHA: action.FECHA};
        case constants.SET_MODO_ACCESO:
            return {...state, modo_acceso: action.modo_acceso};
        case constants.SET_DATOS_MIS_VEHICULOS:
            return {...state, DatosMisVehiculos: action.DatosMisVehiculos};
        default:
            return state;
    }
}

const reducers = combineReducers({updatedState});

const persistConfig = {
    key: 'root',
    storage,
};

const persistedReducer = persistReducer(persistConfig, reducers);
export const store = createStore(persistedReducer, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const persistor = persistStore(store);


/*const composeEnhancers = (window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()) || compose;
const persistedReducer = persistReducer(persistConfig, reducers);
//export const store = createStore(persistedReducer,initialState, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
export const store = createStore(persistedReducer, composeEnhancers);
export const persistor = persistStore(store);*/