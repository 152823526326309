import React from 'react';
import {ButtonGroup, Nav, NavItem, NavLink, Button, Col} from 'reactstrap';
import classnames from 'classnames';
import '../css/cabecera.css';
import Veiasa from "../images/logo_web.png";
import iconoContacto from "../images/icono_contacto.png";
import connect from "react-redux-connect-lifecycle";
import {Table, Row, Cell} from 'react-responsive-table';
import {SetTarget} from "../utils/actions";

const Cabecera = (props) => {

    let estilo = {display: 'contents'};

    function toggle(tab) {
        if (props.activeTab !== tab) {
            props.SetTarget('activeTab', tab);
            if (tab === '2') {
                document.location.hash = "#/citaSolicitud";
            } else if (tab === '1') {
                document.location.href = "https://www.veiasa.es/la_empresa/que_es_veiasa";
            } else {
                document.location.href = "https://www.veiasa.es/metrologia/veiasa-y-la-metrologia";
            }
        }
    }

    function datosContacto(e) {
        e.preventDefault();
        document.location.href = "https://www.veiasa.es/contacto";
    }

    return (
        <header>
            <Nav tabs style={{width: '100%'}} id="Navigation">
                <Row>
                    <Col xl={3} lg={3} md={12} id="logo">
                        <img src={Veiasa} className="img-responsive col-md margin" alt={'VEIASA'}/>
                    </Col>
                    <Col xl={6} lg={6} md={6} className={"d-flex justify-content-center align-items-end"}
                         id="navLinksContainer" style={estilo}>
                        <ul>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: props.activeTab === '1'})}
                                    onClick={() => {
                                        toggle('1');
                                    }}>
                                    La Empresa
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: props.activeTab === '2'})}
                                    onClick={() => {
                                        toggle('2');
                                    }}>
                                    ITV
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({active: props.activeTab === '3'})}
                                    onClick={() => {
                                        toggle('3');
                                    }}>
                                    Metrología
                                </NavLink>
                            </NavItem>
                        </ul>
                    </Col>
                    <Col xl={3} lg={3} md={6} className={"d-flex justify-content-end align-items-end"}>
                        <NavItem>
                            <ButtonGroup vertical>
                                <div className="btn botones" onClick={datosContacto}>Formulario de contacto<span><img
                                    width="21" height="14" style={{marginLeft: '5px'}} src={iconoContacto}/></span>
                                </div>
                            </ButtonGroup>
                        </NavItem>
                    </Col>
                </Row>
            </Nav>

        </header>
    );
};

const mapStateToProps = state => {
    return {
        activeTab: state.updatedState.activeTab
    };
};

const mapDispatchToProps = dispatch => {
    return {
        SetTarget(tName, tValue) {
            dispatch(SetTarget(tName, tValue));
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(Cabecera);
