import constants from "./constants";

export function SetIdioma(idioma) {
    return {type: constants.SET_IDIOMA, idioma};
}

export function SetError(type, error) {
    return {type, error};
}

export function ShowInfoMatBast(visibleInfoMat, visibleInfoBast) {
    return {type: constants.SHOW_INFO_MATBAST, show_info_mat: visibleInfoMat, show_info_bastidor: visibleInfoBast};
}

export function ShowCalendario(visibleCalendario) {
    return {type: constants.SHOW_CALENDARIO, show_calendario: visibleCalendario};
}

export function ShowSpin(visibleSpin) {
    return {type: constants.SHOW_SPIN, show_spin: visibleSpin};
}

export function SetInfo(info) {
    return {type: constants.SET_INFO, info};
}

export function SetToken(token) {
    return {type: constants.SET_TOKEN, token};
}

export function SetOrden(orden) {
    return {type: constants.SET_ORDEN, orden};
}

export function SetTokenInfo(info) {
    return {type: constants.SET_TOKEN_INFO, info};
}

export function SetAutoriza(autoriza) {
    return {type: constants.SET_AUTORIZA, autoriza};
}

export function SetTipInspRemolque(tipInsp) {
    return {type: constants.SET_TIPO_INSP_REMOLQUE, tipInsp};
}

export function SetTipInsp(tipInsp) {
    return {type: constants.SET_TIPO_INSP, tipInsp};
}

export function SetTiposInspRemolque(tiposInsp) {
    return {type: constants.SET_TIPOS_INSPECCIONES_REMOLQUES, tiposInsp};
}

export function SetTiposInsp(tiposInsp) {
    return {type: constants.SET_TIPOS_INSPECCIONES, tiposInsp};
}

export function SetUnidadesToken(unidades) {
    return {type: constants.SET_UNIDADES_TOKEN, unidades};
}

export function SetDescripcion(descripcion) {
    return {type: constants.SET_DESCRIPCION, descrip: descripcion};
}

export function SetElementSelected(bgColor, value) {
    return {type: constants.ELEMENT_SELECTED, bgColor, value};
}

export function ShowAcceso(visibleAcceso) {
    return {type: constants.SHOW_ACCESO, show_acceso: visibleAcceso};
}

export function ShowHoras(visibleHoras) {
    return {type: constants.SHOW_HORAS, show_horas: visibleHoras};
}

export function ShowPagoTarjeta(visiblePagoTarjeta) {
    return {type: constants.SHOW_PAGO_TARJETA, show_pagoTarjeta: visiblePagoTarjeta};
}

export function ShowGrabaOK(visibleGrabaOK) {
    return {type: constants.SHOW_GRABA_OK, show_grabaOK: visibleGrabaOK};
}

export function ShowEliminaOK(visibleEliminaOK) {
    return {type: constants.SHOW_ELIMINA_OK, show_eliminaOK: visibleEliminaOK};
}

export function ShowTelefono(visibleTelefono) {
    return {type: constants.SHOW_TELEFONO, show_telefono: visibleTelefono};
}

export function ShowCorreo(visibleCorreo) {
    return {type: constants.SHOW_CORREO_E, show_correo_e: visibleCorreo};
}

export function SetModal(txtModal, visibleModal) {
    return {type: constants.MODAL, txtModal, showModal: visibleModal};
}

export function ChangeOpc(opcion) {
    return {type: constants.SEL_TAB, opcion};
}

export function ChangeOpcP(opcion_p) {
    return {type: constants.SEL_TAB_P, opcion_p};
}

export function ChangeOpcMb(opcion_mb) {
    return {type: constants.SEL_TAB_MB, opcion_mb};
}

export function UpdateChkDatContacto(chk_telefono, chk_correo_e, D2, D3) {
    return {
        type: constants.UPDATE_CHK_DAT_CONTACTO,
        lcl_chk_telefono: chk_telefono,
        lcl_chk_correo_e: chk_correo_e,
        D2,
        D3
    };
}

export function SetTarget(targetName, targetValue) {
    return {type: constants.SET_TARGET, targetName, targetValue};
}

export function SetPaginaSiguiente(pagina_siguiente) {
    return {type: constants.PAGINA_SIGUIENTE, pagina_siguiente};
}

export function SetFMat(f_mat, moment) {
    return {type: constants.SET_F_MAT, f_mat, moment};
}

export function SetSeconds(seconds) {
    return {type: constants.SET_SECONDS, seconds};
}

export function SetCita(fecha, hora, moment) {
    return {type: constants.SET_CITA, FECHA_CITA: fecha, HORA_CITA: hora, moment};
}

export function SetFlagAceptar(l_aceptar) {
    return {type: constants.SET_L_ACEPTAR, l_aceptar};
}

export function SetInfoTimer(InfoTimer) {
    return {type: constants.SET_INFOTIMER, InfoTimer};
}

export function SetFlagRemolque(l_remolque) {
    return {type: constants.SET_L_REMOLQUE, l_remolque};
}

export function SetTipoInsNP(value) {
    return {type: constants.SET_TIPO_INS_NP, value};
}

export function ClearState() {
    return {type: constants.CLEAR_STATE};
}

export function ClearPartialState() {
    return {type: constants.CLEAR_PARTIAL_STATE};
}

export function SetTipoInsNPRemolque(value) {
    return {type: constants.SET_TIPO_INS_NP_REMOLQUE, value};
}

export function SetTxtUnidad(text) {
    return {type: constants.SET_TXT_UNIDAD, text};
}

export function SetTipoVehiculo(tipVehiculo) {
    return {type: constants.SET_TIPO_VEHICULO, tipVehiculo};
}

export function SetTxtTipoInsp(text) {
    return {type: constants.SET_TXT_TIPO_INSP, text};
}

export function SetTxtTipoInspRemolque(text) {
    return {type: constants.SET_TXT_TIPO_INSP_REMOLQUE, text};
}

export function SetFlagTodasProvincias(l_todas_provincias) {
    return {type: constants.SET_L_TODAS_PROVINCIAS, l_todas_provincias};
}

export function SetConexflow(conexflow) {
    return {type: constants.SET_CONEXFLOW, conexflow};
}

export function SetUnidad(unidad) {
    return {type: constants.SET_UNIDAD, unidad};
}

export function SetProvincia(provincia) {
    return {type: constants.SET_PROVINCIA, provincia};
}

export function SetUnidadesCombo(unidades) {
    return {type: constants.SET_UNIDADES_COMBO, unidadesCombo: unidades};
}

export function SetHorasDisponibles(horasDisponibles) {
    return {type: constants.SET_HORAS_DISPONIBLES, horasDisponibles};
}

export function SetDiasDisponibles(diasDisponibles) {
    return {type: constants.SET_DIAS_DISPONIBLES, diasDisponibles};
}

export function SetHueco(xHueco) {
    return {type: constants.SET_HUECO, X_HUECO: xHueco};
}

export function SetLocalizador(localizador) {
    return {type: constants.SET_LOCALIZADOR, localizador};
}

export function SetPoblaciones(poblaciones) {
    return {type: constants.SET_POBLACIONES, poblaciones};
}

export function SetState(state) {
    return {type: constants.SET_STATE, state};
}

export function SetVisibilidad(dVisibilidad) {
    return {type: constants.SET_VISIBILIDAD, dVisibilidad};
}


export function SetMatricula(matricula) {
    return {type: constants.SET_MATRICULA, matricula};
}

export function SetFlagSolicitaCita(solicitaCita) {
    return {type: constants.SET_FLAG_SOLICITA, solicitaCita};
}

export function SetFlagEliminaCita(eliminaCita) {
    return {type: constants.SET_FLAG_ELIMINA, eliminaCita};
}

export function SetFecha(fecha) {
    return {type: constants.SET_FECHA, FECHA: fecha};
}

export function SetModoAcceso(modo_acceso) {
    return {type: constants.SET_MODO_ACCESO, modo_acceso};
}

export function SetDatosMisVehiculos(DatosMisVehiculos) {
    return {type: constants.SET_DATOS_MIS_VEHICULOS, DatosMisVehiculos};
}