import React, {useState} from 'react';
import handleEnter from '../functions/handleEnter';
import "../css/ImgWidthTooltipStyles.css";

const ImgWithTooltip = (props) => {
    const {alignRight, altText, backColor, closableColor, imgHeight,
        imageId, imgUrlToGo, imgWidth, key, srcImg, textColor, tabIndx,
        textTooltip, tooltipTop, tooltipWidth} = props;
    const [showTooltip, setShowTooltip] = useState(false);
    const onImgClick = () => {
        if (imgUrlToGo) {
            window.open(imgUrlToGo);
            return false;
        }
    };
    return (
        <div id="imgIconTooltip" key={key}>
            {showTooltip && tooltipTop &&
                <div id="tooltipText" className={alignRight?'alignRight top50neg':'top50neg'}
                        style={{backgroundColor: backColor ? backColor : '##337AB7'}}> {/* , width: tooltipWidth ? tooltipWidth : '100%' */}
                    <button id="closedTooltipButton" style={{color: closableColor ? closableColor : '#fff'}}
                        onClick={()=>setShowTooltip(false)}>X</button>
                    <p style={{color: textColor ? textColor : '#fff'}}>{textTooltip}</p>
                </div>
            }
            <img id={imageId?imageId:"imageId"} className="item" width={imgWidth ? imgWidth : 20}
                height={imgHeight ? imgHeight : 20} src={srcImg ? srcImg : require('../images/info.png')}
                onMouseEnter={() => {setShowTooltip(true);}} alt={altText ? altText : ""}
                onClick={onImgClick} onKeyDown={handleEnter} tabIndex={tabIndx?tabIndx:"-1"}
            />
            {showTooltip && !tooltipTop &&
                <div id="tooltipText" className={alignRight?'alignRight':''}
                        style={{backgroundColor: backColor ? backColor : '##337AB7'}}> {/* , width: tooltipWidth ? tooltipWidth : '100%' */}
                    <button id="closedTooltipButton" style={{color: closableColor ? closableColor : '#fff'}}
                        onClick={()=>setShowTooltip(false)}>X</button>
                    <p style={{color: textColor ? textColor : '#fff'}}>{textTooltip}</p>
                </div>
            }
        </div>
    );
}

export default ImgWithTooltip;